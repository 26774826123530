var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"background-container",attrs:{"row":""}},[_vm._l((_vm.logos),function(logo,logoIdx){return _c('div',{key:logoIdx},[_c('div',{staticClass:"opt-select"},[(_vm.menuItemIsDisabled(logo.status))?_c('div',{staticClass:"logo-coming-soon"},[_c('span',[_c('strong',[_vm._v("Coming Soon")])])]):_vm._e(),(
          _vm.settings[logo.type] && _vm.menuItemIsDisabled(logo.status) === false
        )?_c('div',{staticClass:"logo-default"},[_c('span',[_c('strong',[_vm._v("Linked")])])]):_vm._e(),_c('div',{class:`logo-cont ${
          _vm.menuItemIsDisabled(logo.status) === false
            ? 'overlay-active'
            : 'overlay'
        }`,on:{"click":(evt) =>
            _vm.menuItemIsDisabled(logo.status) === false
              ? _vm.activateModal(logo.type)
              : null}},[_c('img',{class:logo.style,attrs:{"src":logo.logo}})])])])}),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(_vm.currentComponent,{tag:"component",attrs:{"close":_vm.close}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }