import charterUp from '@/assets/images/logo-charterup-blue.svg'
import samsara from '@/assets/images/logo-samsara.png'
import keeptruckin from '@/assets/images/logo-keeptruckin.svg'
import plaid from '@/assets/images/logo-plaid.svg'

export const accountTypes = {
  charterUp: {
    status: 1,
    logo: charterUp,
    type: 'charterUp',
    style: 'charterup',
  },
}

export const switchGroupOne = {
  dispatch: {
    label: 'Dispatch',
    key: 'dispatch',
    status: true,
  },
  charterupRates: {
    label: 'CharterUP Rates',
    key: 'charterupRates',
    status: true,
  },
  drivers: {
    label: 'Drivers',
    key: 'drivers',
    status: true,
  },
  company: {
    label: 'Company Info',
    key: 'company',
    status: true,
  },
  tracking: {
    label: 'Tracking',
    key: 'tracking',
    status: true,
  },
  leadfeed: {
    label: 'Lead Feed',
    key: 'leadfeed',
    status: true,
  },
  garages: {
    label: 'Garages',
    key: 'garages',
    status: true,
  },
}

export const switchGroupTwo = {
  vehicles: {
    label: 'Vehicles',
    key: 'vehicles',
    status: true,
  },
  photos: {
    label: 'Photos',
    key: 'photos',
    status: true,
  },
  coachrailRates: {
    label: 'CoachRail Rates',
    key: 'coachrailRates',
    status: false,
  },
  reservations: {
    label: 'Reservations',
    key: 'reservations',
    status: false,
  },
  tickets: {
    label: 'Tickets',
    key: 'tickets',
    status: false,
  },
  quotes: {
    label: 'Quotes',
    key: 'quotes',
    status: false,
  },
  customers: {
    label: 'Customers',
    key: 'customers',
    status: false,
  },
}
