<template>
  <v-layout row wrap class="opt-select">
    <h1 style="margin-left: -10px; font-weight: bold">Company Settings</h1>
    <v-flex xs12>
      <v-layout
        v-if="!preTripArrivalTimeDisabled"
        align-center
        class="mt-4"
        style="margin-bottom: -30px"
      >
        <CRSelect
          id="company-default-time-at-yard"
          v-model="value.defaultTimeAtYard"
          :items="timeAtYardOptions"
          item-value="value"
          item-text="label"
          label="Default Time At Yard"
          placeholder="--"
          :tooltip="`Default time ahead of departure that the driver should arrive at the garage. \n This will determine the pre-trip arrival time, and the default can be overridden per trip itinerary.`"
          :disabled="!isModeEdit"
          browser-autocomplete="off"
        />
      </v-layout>
      <v-layout align-center>
        <v-checkbox
          :id="`${id}-company-settings-checkbox-ticket-cancellation`"
          v-model="value.ticketCancellationRequired.required"
          class="shrink mr-2"
          solo
          :disabled="!isModeEdit"
          hide-details
          @click.stop="
            (val) => {
              update(val, 'ticket-cancellation-required')
            }
          "
        ></v-checkbox>
        <v-flex class="mt-3">
          Ask customer for cancellation confirmation when cancelling a
          reservation
        </v-flex>
      </v-layout>
    </v-flex>

    <h1 style="margin-left: -10px; font-weight: bold; margin-top: 15px">
      Driver App Settings
    </h1>
    <v-flex xs12>
      <v-layout align-center>
        <v-checkbox
          :id="`${id}-payment-profile-checkbox-signature-required`"
          v-model="value.signatureRequired.required"
          class="shrink mr-2"
          solo
          hide-details
          :disabled="!isModeEdit"
          @click.stop="
            (val) => {
              update(val, 'signature-required')
            }
          "
        ></v-checkbox>
        <v-flex style="margin-top: 19px">
          Require passenger signature on first pickup
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout align-center>
        <v-checkbox
          :id="`${id}-payment-profile-tracking-odometry`"
          v-model="isTrackingOdometry"
          class="shrink mr-2"
          :disabled="!isModeEdit"
          hide-details
          @click.stop="(val) => update(val, 'tracking-odometry')"
        ></v-checkbox>
        <v-flex style="margin-top: 19px">
          Capture vehicle odometer on trip start and trip end
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { timeAtYardOptions } from '@/utils/garageTimes.js'

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'view',
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      preTripArrivalTimeDisabled: false,
    }
  },
  computed: {
    isModeEdit() {
      return this.mode === 'edit'
    },
    isTrackingOdometry() {
      return this.value.isTrackingOdometry.required
    },
    isTrackingTicketCancellation() {
      return this.value.isTrackingTicketCancellation.required
    },
    timeAtYardOptions() {
      return timeAtYardOptions
    },
  },
  mounted() {
    const { getters = {} } = this.$store
    this.preTripArrivalTimeDisabled =
      getters['systemParameters/preTripArrivalTimeDisabled']
  },
  methods: {
    update(val, name) {
      this.$emit('update', { name, val })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/bulma';

.opt-select {
  display: flex;
  padding: 30px;
}
</style>
