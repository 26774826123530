<template>
  <div class="gateway-option">
    <div
      v-if="paymentGateway && paymentGateway.companyPaymentGatewayId"
      class="logo-default"
    >
      <span><strong>Linked</strong></span>
      <span v-if="paymentGateway && paymentGateway.defaultGateway">
        <v-icon color="white" small>check</v-icon>
      </span>
    </div>

    <div
      v-if="image === 'auth_net'"
      :id="`${id}-gateway-form-div-enable-edit-authorize.net`"
      :class="logo"
      @click="(evt) => enableEdit('authorize.net')"
    >
      <img src="@/assets/images/logo-authorize.svg" />
    </div>

    <v-dialog v-model="edit" :transition="false" width="500">
      <v-card class="cr-modal" width="100%">
        <v-card-title>
          <h2>{{ isEdit ? 'Edit' : 'Add' }} Payment Gateway</h2>
        </v-card-title>

        <v-card-text>
          <v-flex>
            <div>
              <img
                class="modal-logo"
                src="@/assets/images/logo-authorize.svg"
              />
            </div>
          </v-flex>

          <v-flex class="text-xs-right">
            <v-btn
              v-if="isEdit && !isDefaulted"
              :id="`${id}-gateway-form-div-button-make-default`"
              :disabled="loading"
              :loading="loading"
              class="mark-default-button btn-pushup btn-primaryaction"
              @click="makeDefault"
            >
              Make Default
            </v-btn>
            <v-btn
              v-if="isEdit && isDefaulted"
              class="mark-default-button btn-pushup btn-primaryaction"
            >
              Defaulted
              <v-icon>check</v-icon>
            </v-btn>
          </v-flex>

          <br v-if="!isEdit" />

          <CRInput
            :id="`${id}-gateway-form`"
            ref="ak"
            v-model="model.apiKey"
            :error-messages="errors.ak"
            :floatinglabel="apiKeyLabel"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: `${apiKeyLabel} Required`,
                error: `${apiKeyLabel} Required`,
              }),
            ]"
            :placeholder="
              model.apiKey.length > 0 ? null : `Please Enter ${apiKeyLabel}`
            "
            @change="
              (evt) => {
                onChange(evt, 'apiKey', 'ak')
              }
            "
          />
          <CRInput
            :id="`${id}-gateway-form`"
            ref="tk"
            v-model="model.secretKey"
            :error-messages="errors.tk"
            :floatinglabel="secretKeyLabel"
            tooltip="For your security, your existing token is not shown"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: `${secretKeyLabel} Required`,
                error: `${secretKeyLabel} Required`,
              }),
            ]"
            :placeholder="
              model.secretKey.length > 0
                ? null
                : `Please Enter ${secretKeyLabel}`
            "
            @change="
              (evt) => {
                onChange(evt, 'secretKey', 'tk')
              }
            "
          />

          <CRInput
            :id="`${id}-gateway-form`"
            ref="ck"
            v-model="model.clientKey"
            :error-messages="errors.ck"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: `${clientKeyLabel} Required`,
                error: `${clientKeyLabel} Required`,
              }),
            ]"
            :placeholder="
              model.clientKey.length > 0
                ? null
                : `Please Enter ${clientKeyLabel}`
            "
            :floatinglabel="clientKeyLabel"
            @change="
              (evt) => {
                onChange(evt, 'clientKey', 'ck')
              }
            "
          />

          <div v-if="displayerr" class="errormsg">
            {{ errmsg }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              :id="`${id}-gateway-form-button-close`"
              class="btn-secondaryaction"
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              v-if="gatewayMode === 'edit'"
              :id="`${id}-gateway-form-button-save-changes`"
              :disabled="loading"
              :loading="loading"
              class="btn-primaryaction"
              @click="submit"
            >
              Save Changes
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isNotEmpty, isRequired } from '@/utils/validators'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
    paymentGateway: {
      type: Object,
      default: () => undefined,
    },
    paymentGatewayTypeKey: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      gatewayMode: 'view',
      topLabel: 'Add',
      loading: false,
      disabled: true,
      displayerr: false,
      errors: {},
      errmsg: '',
      edit: false,
      noneFound: false,
      model: {
        apiKey: '',
        secretKey: '',
        clientKey: '',
      },
      image: null,
      data: null,
    }
  },
  computed: {
    ...authComputed,
    logo() {
      return this.gatewayMode === 'view'
        ? 'logo-cont overlay-active'
        : 'logo-cont overlay-active'
    },
    isEdit() {
      return (
        this.gatewayMode === 'edit' &&
        this.paymentGateway &&
        this.paymentGateway.companyPaymentGatewayId
      )
    },
    isDefaulted() {
      return (
        this.gatewayMode === 'edit' &&
        this.paymentGateway &&
        this.paymentGateway.defaultGateway
      )
    },
    isAuthNet() {
      return this.paymentGatewayTypeKey === 'auth_net'
    },
    apiKeyLabel() {
      if (this.isAuthNet) {
        return 'API Login ID'
      }
      return ''
    },
    secretKeyLabel() {
      if (this.isAuthNet) {
        return 'Transaction Key'
      }
      return ''
    },
    clientKeyLabel() {
      if (this.isAuthNet) {
        return 'Client Key'
      }
      return ''
    },
  },
  mounted() {
    this.prepModel()
  },
  updated() {
    this.prepModel()
  },
  methods: {
    prepModel() {
      try {
        this.image = this.paymentGatewayTypeKey
        if (this.paymentGateway) {
          this.paymentGateway.secretKey = ''
          this.model = this.paymentGateway
        }
      } catch (e) {
        this.noneFound = true
      }
    },
    enableEdit(type) {
      this.disabled = false
      this.edit = true
      if (this.paymentGateway?.companyPaymentGatewayId) {
        this.topLabel = 'Edit'
      } else {
        this.topLabel = 'Add'
      }
      this.gatewayMode = 'edit'
    },
    displayErr(e, status) {
      this.displayerr = status
      this.errmsg = e
      setTimeout(() => {
        this.displayerr = false
      }, 4000)
    },
    onChange(evt, field, errkey) {
      this.errors[errkey] = []
    },
    async submit() {
      const dumbMap = {
        tk: 'secretKey',
        ck: 'clientKey',
        ak: 'apiKey',
      }
      let refs = ['ak', 'ck', 'tk']

      const test = refs.reduce((acc, reference) => {
        const el = this.$refs[reference]
        const elementState = el.$attrs.rules.map((rule) => {
          let testSubject = el.value
          const prop = dumbMap[reference]
          testSubject = this.model[prop]
          const test = rule(testSubject)
          if (typeof test === 'string') {
            this.errors[reference] = [test]
            return test
          }

          return false
        })
        acc = [...acc, ...elementState]
        return acc
      }, [])

      if (test.filter(Boolean).length > 0) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be required or are invalid.',
          true
        )
        this.$forceUpdate()
        return
      }
      const payload = this.model
      payload.paymentGatewayTypeKey = this.paymentGatewayTypeKey
      if (!payload.checkoutPageId) {
        delete payload.checkoutPageId
      }
      let res
      if (this.paymentGateway?.companyPaymentGatewayId) {
        res = await this.$store.dispatch(
          'payments/updatePaymentGateway',
          payload
        )
      } else {
        res = await this.$store.dispatch('payments/addPaymentGateway', payload)
      }

      if (!res) {
        this.displayErr('Please Retry with valid credentials.', true)
      } else {
        this.$forceUpdate()
        this.closeDialog()
      }
    },
    async makeDefault() {
      await this.$store.dispatch(
        'payments/setDefaultPaymentGateway',
        this.paymentGateway?.companyPaymentGatewayId
      )
      this.closeDialog()
    },
    closeDialog() {
      this.gatewayMode = 'view'
      this.edit = false
      this.$emit('close')
    },
    isNotEmpty,
    isRequired,
  },
}
</script>

<style lang="scss" scoped>
.header {
  margin-top: -60px;
  background-color: $blue;
  display: flex;
  justify-content: space-between;
  height: 140px !important;
}

.close {
  position: relative;
  bottom: 5px;
  padding-right: 5px;
  font-size: 30px;
  color: lightgrey;
}

.buttons {
  position: relative;
  left: -8px;
  bottom: 8px;
  display: flex;
  justify-content: flex-end;
}

.gateway-dialog {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
  min-height: 500px;
  padding: 35px;
  margin: 24px;
  margin-bottom: 40%;
  background-color: $white;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.gateway-option {
  display: flex;
  margin: 30px;
  flex-direction: column;
  width: 120px;
  height: 100px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.logo-cont {
  display: flex;
  justify-content: center;
  border-radius: 10px !important;
  background-color: $white;
  cursor: pointer;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.overlay-active {
  position: relative;
  z-index: 0;
}

.overlay-active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  /* !!! */
  z-index: -1;
}

.overlay {
  position: relative;
  z-index: 0;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $shofur-text-lighter;
  opacity: 0.6;
  border-radius: 6px;
  /* !!! */
  z-index: -1;
}

.errormsg {
  top: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $error;
  border-radius: 2px;
}

.logo-default {
  display: flex;
  font-weight: 600;
  background-color: $blue;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: $white;
  text-align: left;
  padding-left: 6px;
  width: 120px;
  position: absolute;
  :last-child {
    margin-right: 52px;
  }
  z-index: 1;
}

.mark-default-button {
  border: 1px solid $white !important;
  margin-right: 0px;
}
.token-message {
  border: 1px solid $red;
  position: relative;
  top: 21px;
  left: 124px;
}
.modal-logo {
  margin-top: -75px;
  margin-bottom: -75px;
  height: 150px;
  width: 150px;
}
.btn-pushup {
  margin-top: -25px;
}
</style>
<style scoped>
::v-deep .v-dialog.v-dialog--active {
  display: flex;
  justify-content: center;
  margin: 0;
  overflow-y: hidden;
  pointer-events: auto;
  border-radius: 0;
  box-shadow: none;
}

::v-deep .v-card {
  box-shadow: none;
}
</style>
