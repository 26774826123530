import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { CharterUPSync } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  createCharterUpSettings(body: CharterUPSync): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charterUpSync`
    return httpService.post(url, body)
  },
  editCharterUpSettings(body: CharterUPSync): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charterUpSync`
    return httpService.patch(url, body)
  },
  getCharterUpSettings(): Promise<AxiosResponse<CharterUPSync>> {
    return httpService.get(`https://${baseUrl()}/charterUpSync`)
  },
}
