<template>
  <v-layout class="background-container" row>
    <div v-for="(logo, logoIdx) in logos" :key="logoIdx">
      <div class="opt-select">
        <div v-if="menuItemIsDisabled(logo.status)" class="logo-coming-soon">
          <span><strong>Coming Soon</strong></span>
        </div>
        <div
          v-if="
            settings[logo.type] && menuItemIsDisabled(logo.status) === false
          "
          class="logo-default"
        >
          <span><strong>Linked</strong></span>
        </div>
        <div
          :class="`logo-cont ${
            menuItemIsDisabled(logo.status) === false
              ? 'overlay-active'
              : 'overlay'
          }`"
          @click="
            (evt) =>
              menuItemIsDisabled(logo.status) === false
                ? activateModal(logo.type)
                : null
          "
        >
          <img :class="logo.style" :src="logo.logo" />
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" width="700">
      <component :is="currentComponent" :close="close" />
    </v-dialog>
  </v-layout>
</template>
<script>
import { accountTypes } from './LinkAccountModels'
import CharterUpWorkFlow from './CharterUpWorkFlow.vue'
import { authComputed } from '@/state/helpers'
import accountSync from '@/services/accountSync'

export default {
  components: {
    CharterUpWorkFlow,
  },
  data() {
    return {
      dialogProp: {
        provider: '700',
        broker: '600',
      },
      logos: [],
      dialog: false,
      workflowTypes: {
        charterUp: CharterUpWorkFlow,
      },
      settings: {
        charterUp: false,
      },
      currentComponent: CharterUpWorkFlow,
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    let logos = accountTypes
    this.logos = logos

    if (this.$route.query?.link) {
      if (this.$route.query.link === 'charterup') {
        this.dialog = true
        this.currentComponent = CharterUpWorkFlow
      }
    }

    const existingSettings = await accountSync
      .getCharterUpSettings()
      .then((data) => data.data)

    if (existingSettings.charterupCoachrailSyncId) {
      this.settings.charterUp = true
    }
  },
  methods: {
    menuItemIsDisabled(item) {
      if (!item) return true
      if (item === 0) return true
      if (item === 1) return false

      const { getters = {} } = this.$store
      return getters[`systemParameters/${item}`]
    },
    activateModal(type) {
      this.currentComponent = this.workflowTypes[type]
      this.dialog = true
    },
    checkAndActivateModal(item, type) {
      if (!this.menuItemIsDisabled(item)) {
        return this.activateModal(type)
      } else {
        return null
      }
    },
    close() {
      this.dialog = false
    },
  },
}
</script>
<style lang="scss" scoped>
.background-container {
  flex-wrap: wrap;
  background-color: $blue-light;
}

.opt-select {
  display: flex;
  margin: 30px;
  flex-direction: column;
  width: 120px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100px;
}

.logo-default {
  display: flex;
  font-weight: 600;
  background-color: $primary;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: $white;
  text-align: left;
  padding-left: 6px;
  width: 120px;
  position: absolute;
  :last-child {
    margin-right: 70px;
  }
  z-index: 1;
}

.logo-coming-soon {
  font-weight: 600;
  background-color: $shofur-text-lighter;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: $white;
  text-align: left;
  padding-left: 6px;
  width: 120px;
  position: absolute;
  z-index: 1;
}

.overlay-active {
  position: relative;
  z-index: 0;
}

.overlay-active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  /* !!! */
  z-index: -1;
}

.overlay {
  position: relative;
  z-index: 0;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $shofur-text-lighter;
  opacity: 0.6;
  border-radius: 6px;
  /* !!! */
  z-index: -1;
}

.logo-cont {
  display: flex;
  justify-content: center;
  border-radius: 10px !important;
  cursor: pointer;
  background-color: $white;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    &.samsara {
      padding: 22px;
      position: relative;
      top: 5px;
    }
  }
}
</style>
