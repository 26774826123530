var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-form-container",style:(!_vm.isModeAdd ? 'padding-bottom: 48px;' : '')},[_c('v-snackbar',{attrs:{"id":`companies-form-snackbar-error-display`,"top":true,"color":"error","multi-line":_vm.isModeMultiLine,"timeout":0,"vertical":_vm.isModeVertical,"type":"error"},model:{value:(_vm.displayerr),callback:function ($$v) {_vm.displayerr=$$v},expression:"displayerr"}},[_vm._v(" "+_vm._s(_vm.errmsg)+" "),_c('v-btn',{attrs:{"id":`companies-form-button-close-error`,"dark":"","flat":""},on:{"click":function($event){_vm.displayerr = false}}},[_vm._v(" Close ")])],1),_c('BaseForm',{ref:"form",attrs:{"id":`companies-form`,"disabled":_vm.disabled,"form-type":_vm.formType,"layout":_vm.layout,"loading":_vm.loading,"row":_vm.row,"select-models":_vm.selectModels},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"button-container"},[(_vm.isBrokerAdmin || _vm.isSuperAdmin)?_c('v-btn',{staticClass:"btn-secondaryaction",attrs:{"id":`companies-form-button-add-user`},on:{"click":() => {
              _vm.$router.push({ name: 'users.add', query: { companyId: _vm.id } })
            }}},[_vm._v(" Add User ")]):_vm._e(),(!_vm.isModeMyCompany)?_c('v-btn',{staticClass:"btn-secondaryaction",attrs:{"id":`companies-form-button-cancel-action`},on:{"click":_vm.cancelAction}},[_vm._v(" "+_vm._s(_vm.isModeView ? 'All Companies' : 'Cancel')+" ")]):_vm._e(),(_vm.isModeEdit)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`companies-form-button-save-changes`,"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Save Changes ")]):_vm._e(),(_vm.isModeAdd)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`companies-form-add-company`,"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" + Add Company ")]):_vm._e(),(_vm.isModeView || _vm.isModeMyCompany)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`companies-form-button-edit-company`},on:{"click":_vm.enableEdit}},[_vm._v(" Edit Company ")]):_vm._e()],1)]},proxy:true},{key:"address",fn:function(){return [_c('div',{staticClass:"pb-5"},[_c('div',[_vm._v("Address")]),(!_vm.address.addressName)?_c('CRAddressSelector',{attrs:{"id":`companies-form`,"disabled":_vm.disabled,"show-options":_vm.showOptions,"placeholder":"Type to search","use-formatted-detail":true,"rules":[
            _vm.isRequired(true, () => _vm.validateAddress(_vm.address), {
              req: 'Address Is Required',
              error: 'Address Is Required',
            }),
          ]},on:{"place-selected":(selectedData) => _vm.placeSelected(selectedData)}}):_vm._e(),(_vm.address && _vm.address.addressName)?_c('CRInput',{staticClass:"cr-input-stop-address",attrs:{"id":`companies-form-address-name`,"type":"text","clear-icon":"replay","clearable":true,"readonly":true,"disabled":_vm.disabled,"value":_vm.address.addressName || '',"rules":[
            _vm.isRequired(true, () => _vm.validateAddress(_vm.address), {
              req: 'Address Is Required',
              error: 'Address Is Required',
            }),
          ]},on:{"click:clear":() => (_vm.address = {})}}):_vm._e()],1)]},proxy:true}])}),(!_vm.isModeAdd)?_c('PaymentProfile',{ref:"paymentprofile",attrs:{"id":`companies-form`,"company":_vm.row,"mode":_vm.mode,"select-models":_vm.selectModels},on:{"update":_vm.handleSettingUpdate}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }