<template>
  <v-layout v-if="!isEnabled" class="cupops-settings-container" row wrap>
    <v-flex xs12>
      Setting this flag will enable this company to login to CharterUP for
      Operators and cannot be undone.
    </v-flex>
    <v-flex xs12>
      <v-btn
        :id="`cupops-settings-enable-button`"
        :loading="loading"
        :disabled="loading"
        class="btn-primaryaction cupops-settings-button"
        @click="confirm"
      >
        Enable CharterUP for Operator
      </v-btn>
    </v-flex>
    <v-dialog v-model="confirming" max-width="500px">
      <Confirm
        header="Are you sure?"
        message="Setting this flag will enable this company to login to CharterUP for Operators and cannot be undone."
        :is-dialog="true"
        @cancel="cancel"
        @confirm="enable"
      />
    </v-dialog>
  </v-layout>
  <v-layout v-else class="cupops-settings-container" row wrap>
    <v-flex xs12>
      This company is enabled to login to CharterUP for Operators.
    </v-flex>
    <v-flex xs12>
      <v-btn
        :id="`cupops-settings-enable-button-disabled`"
        :disabled="true"
        class="cupops-settings-button"
      >
        CharterUP for Operator Enabled
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import Confirm from '@/components/Confirm.vue'
import companies from '@/services/companies'
export default {
  components: {
    Confirm,
  },
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      confirming: false,
      loading: false,
      isEnabled: false,
    }
  },
  mounted() {
    this.isEnabled = this.company?.cupopsEnabled
  },
  methods: {
    confirm() {
      this.confirming = true
    },
    async enable() {
      this.cancel()
      this.loading = true
      this.isEnabled = await companies
        .enableCUPOPS(this.company?.companyId)
        .then(() => true)
        .catch(() => false)
      this.loading = false
    },
    cancel() {
      this.confirming = false
    },
  },
}
</script>

<style>
.cupops-settings-container {
  padding-left: 14px;
}
.cupops-settings-button {
  margin-left: 0px;
}
</style>
