<template>
  <div class="dropzone" :class="disabled ? 'disabled' : ''">
    <input
      v-if="uploadedFile === undefined"
      id="partnership-settings-pdf-upload-input"
      ref="inputfile"
      accept="application/pdf"
      class="upload-input"
      type="file"
      :disabled="disabled"
      @input="captureFile"
    />
    <div>
      <CRIcon v-if="uploadSuccess" color="primary">done</CRIcon>
      <CRIcon v-if="!uploadSuccess" color="primary">attachment</CRIcon>
    </div>
    <div v-if="uploadSuccess">
      <strong>{{ fileName }}</strong>
    </div>
    <span
      v-if="isFileInDatabase && uploadedFile === undefined"
      style="margin-top: -6px"
    >
      We have an insurance policy PDF on file.
      <br />
      To replace it, drap and drop your PDF here or
      <strong><a>Click to Upload</a></strong>
    </span>
    <div v-else-if="!uploadSuccess">
      Drag and drop your PDF here or
      <strong>
        <a>Click To Upload</a>
      </strong>
    </div>
    <div>
      <a v-if="uploadedFile !== undefined" @click.prevent="removeFile">
        Remove
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    value: {
      type: FormData,
      default: () => {},
    },
    disabled: {
      type: Boolean,
    },
    isFileInDatabase: {
      type: Boolean,
    },
  },
  data() {
    return {
      fileName: undefined,
      uploadSuccess: false,
      uploadedFile: undefined,
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    captureFile(evt) {
      const file = evt.target.files[0]
      this.uploadSuccess = true
      this.fileName = file.name

      const formData = new FormData()
      formData.append('file', file)
      this.uploadedFile = formData
      this.$emit('input', this.uploadedFile)
    },
    removeFile(event) {
      this.fileName = undefined
      this.uploadedFile = undefined
      this.uploadSuccess = false
      this.$emit('input', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropzone {
  transition: background-color 0.1s ease-out 0.1s;
  background-color: $white;
  border: 1px solid $gray-medium-light;
  border-radius: 8px;
  width: 100%;
  height: 117px;
  display: flex;
  flex-direction: row;
  padding: 42px 0;
  position: relative;

  > * {
    flex-grow: 1;
    text-align: center;
  }

  &.disabled {
    background-color: $blue-dull !important;
  }

  .upload-input {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 8px;
    height: 117px;
    cursor: pointer;
    opacity: 0;
  }
}
</style>
