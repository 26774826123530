import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'

const httpService: HttpService = new HttpService()

export const namespaced = true

export default {
  getLinkedAccountDetailByProfileId(linkedAccountProfileId: number) {
    const host = baseUrl()
    const url = `https://${host}/v2/linked-account-details/linked-account-profiles/${linkedAccountProfileId}`
    return httpService.get(url)
  },
}
