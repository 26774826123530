<template>
  <v-form ref="partnershipSettingsForm" v-model="valid" lazy-validation>
    <v-layout v-if="displayEdit" class="pb-4" row justify-end>
      <v-flex shrink>
        <CRButton v-if="isModeView" color="primary" @click="toggleEdit">
          Edit Company
        </CRButton>
        <CRButton
          v-if="isModeEdit"
          color="secondary"
          class="mr-1"
          @click="cancelEdit"
        >
          Cancel
        </CRButton>
        <CRButton
          v-if="isModeEdit"
          :disabled="loading"
          :loading="loading"
          color="primary"
          @click="submit"
        >
          Save
        </CRButton>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <!-- PARTNER TYPE LINE -->
      <v-flex xs3>
        <label>
          Partner Type
          <v-tooltip top max-width="250">
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                style="cursor: pointer"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            This determines the ranking of bids and company visibility in
            Marketplace
          </v-tooltip>
        </label>
        <CRSelect
          id="partnership-settings-partner-type"
          v-model="companyInfo.partnerTypeId"
          :disabled="
            isModeView || brandLicenseOptions.partnerTypeDropdownDisabled
          "
          :items="partnerTypeOptions"
          item-text="label"
          item-value="id"
          placeholder="Select Type"
          style="margin-top: 5px !important"
          @change="isFormValid"
          @input="addToPayload('partnerTypeId', companyInfo.partnerTypeId)"
        />
      </v-flex>
      <v-flex xs3>
        <label>Onboarding Status</label>
        <CRSelect
          id="partnership-settings-onboarding-status"
          v-model="companyInfo.onboardingStatusTypeId"
          :items="onboardingStatusTypes"
          :disabled="isModeView"
          item-text="label"
          item-value="id"
          placeholder="Select Onboarding Status"
          style="margin-top: 5px !important"
          @input="
            addToPayload(
              'onboardingStatusTypeId',
              companyInfo.onboardingStatusTypeId
            )
          "
        />
      </v-flex>
      <v-flex xs3>
        <CRSelect
          id="partnership-settings-charterup-operating-status"
          v-model="companyInfo.charterUPOperatingStatusId"
          :items="charterUPOperatingStatusTypes"
          :disabled="isModeView"
          item-text="label"
          item-value="id"
          label="CharterUP Operating Status"
          placeholder=" "
          @input="addToPayload('charterUPOperatingStatusId', companyInfo.charterUPOperatingStatusId)"
        />
      </v-flex>
      <v-flex xs3>
        <label v-if="brandLicenseOptions.brandLicenseDropdownVisible">
          Brand License
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                style="cursor: pointer"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            This determines which partnership agreement they’ve signed
          </v-tooltip>
        </label>
        <CRSelect
          v-if="brandLicenseOptions.brandLicenseDropdownVisible"
          id="partnership-settings-brand-license"
          v-model="companyInfo.brandLicenseeId"
          :items="brandLicenseOptions.brandChoices"
          :disabled="
            isModeView || brandLicenseOptions.brandLicenseDropdownDisabled
          "
          item-text="label"
          item-value="id"
          placeholder="Select Brand"
          style="margin-top: 5px !important"
          @input="changeBrandLicense"
        />
      </v-flex>

      <template v-if="showPublicSiteControls">
        <v-flex xs1>
          <label v-if="companyInfo">Public Site Enabled</label>
          <div style="white-space: nowrap">
            <v-switch
              id="public-site-enabled-toggle"
              v-model="companyInfo.publicSiteEnabled"
              style="display: inline-flex; margin-top: 0"
              :disabled="isModeView"
            />
            <v-icon style="display: inline-flex" @click="openPublicPage">
              open_in_new
            </v-icon>
          </div>
        </v-flex>
        <v-flex xs2>
          <CRInput
            v-model="companyInfo.publicSlug"
            label="Public Slug"
            placeholder="e.g. acme-caribbean"
            type="text"
            :rules="rules.publicSlug"
            :disabled="isModeView"
            @change="addToPayload('publicSlug', companyInfo.publicSlug)"
          />
        </v-flex>
      </template>

      <!-- PARTNER NAME LINE -->
      <v-flex :key="`default-market-name-input`" xs3>
        <CRInput
          :id="`partnership-settings-default-partner-name`"
          v-model="company.name"
          disabled
          label="Partner Name"
          :rules="rules.partnerName"
          type="text"
        />
      </v-flex>
      <v-flex :key="`market-default-market-selector`" xs3>
        Default Market
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              small
              v-bind="attrs"
              style="cursor: pointer"
              v-on="on"
            >
              info
            </v-icon>
          </template>
          The company will receive business in this market because their primary address is listed here.
        </v-tooltip>
        <CRSelect
          :id="`partnership-settings-default-market`"
          v-model="company.address.nearestMarketId"
          disabled
          :items="markets"
          item-text="marketName"
          item-value="marketId"
          placeholder="Select Market"
          class="margin-t-1"
        />
      </v-flex>
      <v-flex :key="`market-default-market-selector`" xs3>
      </v-flex>

      <template v-for="(market, marketIndex) in companyInfo.dbaNameList">
        <v-flex :key="`market-${marketIndex}-open-in-charterup`" xs3></v-flex>
        <v-flex :key="`market-${marketIndex}-name-input`" xs3>
          <CRIcon
            v-if="showDeleteMarketIcon"
            class="delete-icon"
            style="display: inline-block"
            :disabled="isModeView"
            @click.native="deleteMarket(marketIndex)"
          >
            trash
          </CRIcon>
          <CRInput
            :id="`partnership-settings-partner-name-${marketIndex}`"
            v-model="market.name"
            :disabled="isModeView"
            label="Partner Name"
            :rules="rules.partnerName"
            placeholder="e.g. ACME Charter Bus and Limo"
            :style="
              showDeleteMarketIcon
                ? 'display: inline-block; width: calc(100% - 40px);'
                : ''
            "
            type="text"
            @change="isFormValid"
          />
        </v-flex>
        <v-flex :key="`market-${marketIndex}-market-selector`" xs3>
          <CRSelect
            :id="`partnership-settings-market-${marketIndex}`"
            v-model="market.marketId"
            :disabled="isModeView"
            label="Market"
            :items="markets"
            item-text="marketName"
            item-value="marketId"
            placeholder="Select Market"
            @input="processMarkets"
          />
        </v-flex>
        <v-flex :key="`market-${marketIndex}-open-in-charterup`" xs3>
          <v-icon
            style="margin-top: 32px"
            @click="openInCharterUP(market.marketId)"
          >
            open_in_new
          </v-icon>
        </v-flex>
      </template>

      <v-flex xs12 class="mb-4" style="margin-top: -16px">
        <a v-if="showAddNewMarket" @click="addMarket">Add New Market</a>
      </v-flex>

      <v-flex xs3>
        <CRInput
          :id="`partnership-settings-out-of-market-partner-name-${marketIndex}`"
          v-model="companyInfo.outOfMarketDbaName.name"
          :disabled="isModeView"
          label="Out of Market Partner Name"
          :rules="rules.outOfMarketPartnerName"
          placeholder="e.g. ACME Charter Bus and Limo"
          type="text"
          @change="isFormValid"
        />
      </v-flex>
      <v-flex xs9></v-flex>

      <v-flex xs3>
        <v-checkbox
          id="partnership-settings-is-preferred"
          v-model="companyInfo.isPreferred"
          :disabled="isModeView || brandLicenseOptions.isPreferredDisabled"
          label="Is Certified Partner"
          class="mr-2"
          style="margin-top: 20px"
          hide-details
          @change="addToPayload('isPreferred', companyInfo.isPreferred)"
        />
      </v-flex>
      <v-flex xs3>
        <v-checkbox
          id="partnership-settings-marketplace-email-opt-out"
          v-model="sendMarketplaceEmails"
          :disabled="isModeView"
          class="mr-2"
          style="margin-top: 20px"
          label="Opt Out of Marketplace Emails"
          @change="toggleSendMarketplaceEmails"
        />
      </v-flex>
      <v-flex xs3></v-flex>
      <v-flex xs3>
        <CRInput
          id="partnership-settings-operator-quality"
          v-model="companyInfo.quality"
          :disabled="isModeView"
          label="Operator Quality"
          placeholder="e.g.  .75 "
          type="number"
          :rules="rules.quality"
          @change="
            addToPayload('quality', companyInfo.quality)
            isFormValid()
          "
        />
      </v-flex>

      <!-- PARTNER URL LINE -->
      <v-flex xs6>
        <CRInput
          id="partnership-settings-partner-url"
          v-model="companyInfo.customerFacingUrl"
          :disabled="isModeView"
          label="Partner URL"
          :rules="rules.partnerUrl"
          placeholder="e.g. www.acmecharterbuslimo.com"
          type="text"
          @change="
            addToPayload('customerFacingUrl', companyInfo.customerFacingUrl)
          "
        />
      </v-flex>
      <v-flex xs3>
        <CRInput
          id="partnership-settings-ratings-stars"
          v-model="companyInfo.companyRating"
          :disabled="isModeView"
          type="number"
          step=".1"
          label="Ratings Stars"
          placeholder="e.g. 4.7"
          :max="5"
          :min="0"
          :rules="rules.ratingsStars"
          @change="
            addToPayload('companyRating', parseFloat(companyInfo.companyRating))
          "
        />
      </v-flex>
      <v-flex xs3>
        <CRInput
          id="partnership-settings-ratings-count"
          v-model="companyInfo.reviewCount"
          :disabled="isModeView"
          label="Ratings Count"
          placeholder="e.g. 145"
          type="number"
          :rules="rules.ratingsCount"
          @change="
            addToPayload('reviewCount', companyInfo.reviewCount)
            isFormValid()
          "
        />
      </v-flex>

      <!-- PARTNER PHONE LINE -->
      <v-flex xs3>
        <CRInput
          id="partnership-settings-partner-phone"
          v-model="companyInfo.partnerPhone"
          :disabled="isModeView"
          label="Partner Phone"
          placeholder="e.g. (123) 133-2131"
          :rules="rules.partnerPhone"
          type="text"
          mask="(###) ###-####"
          @change="
            addToPayload('phone', companyInfo.phone)
            isFormValid()
          "
        />
      </v-flex>
      <v-spacer />
      <v-flex xs6>
        <CRSelect
          :id="`partnership-settings-linked-lead-sources`"
          v-model="companyInfo.linkedLeadSources"
          name="partnership-settings-linked-lead-sources"
          item-text="label"
          item-value="id"
          :items="companyInfo.linkableLeadSources"
          :disabled="isModeView"
          label="Linked Lead Sources"
          placeholder=" "
          chips
          multiple
          solo
          flat
          return-object
          @input="
            addToPayload('linkedLeadSources', companyInfo.linkedLeadSources)
          "
        />
      </v-flex>

      <!-- PARTNER DESCRIPTION LINE -->
      <v-flex xs6>
        <CRInput
          id="partnership-settings-partner-description"
          v-model="companyInfo.description"
          :disabled="isModeView"
          multi-line
          auto-grow
          label="Partner Description"
          :rules="rules.partnerDescription"
          maxlength="1000"
          counter="1000"
          placeholder="Enter description of the partner company to be shown in CharterUP Marketplace"
          @change="addToPayload('description', companyInfo.description)"
        />
      </v-flex>

      <v-flex xs6>
        <CRInput
          id="partnership-settings-partner-cancellation"
          v-model="companyInfo.cancellationPolicy"
          :disabled="isModeView"
          multi-line
          auto-grow
          label="Partner Cancellation"
          maxlength="1000"
          counter="1000"
          placeholder="Enter cancellation policy of the partner company to be shown in CharterUP Marketplace"
          :rules="rules.partnerCancellation"
          @change="
            addToPayload('cancellationPolicy', companyInfo.cancellationPolicy)
          "
        />
      </v-flex>

      <!-- PARTNER INSURANCE LINE -->
      <v-flex xs6>
        <CRInput
          id="partnership-settings-partner-insurance"
          v-model="companyInfo.insurancePolicy"
          :disabled="isModeView"
          multi-line
          auto-grow
          label="Partner Insurance"
          maxlength="1000"
          counter="1000"
          placeholder="Enter insurance policy of the partner company to be shown in CharterUP Marketplace"
          :rules="rules.partnerInsurance"
          @change="addToPayload('insurancePolicy', companyInfo.insurancePolicy)"
        />
      </v-flex>

      <v-flex xs6>
        <!-- TODO: MAKE THIS A V-MODEL -->
        <PartnershipSettingsPDFDragAndDrop
          v-model="insurancePdf"
          style="margin-top: 25px"
          :disabled="isModeView || photoModal.show"
          :is-file-in-database="insurancePdfExists"
        />
      </v-flex>

      <v-flex xs3>
        <CRInput
          id="partnership-settings-miles-last-year"
          v-model="companyInfo.milesLastYear"
          :disabled="isModeView"
          label="Miles Last Year"
          placeholder="e.g. 125000"
          type="number"
          @change="addToPayload('milesLastYear', companyInfo.milesLastYear)"
        />
      </v-flex>
      <v-flex xs3>
        <CRInput
          id="partnership-settings-accidents-last-year"
          v-model="companyInfo.accidentsLastYear"
          :disabled="isModeView"
          label="Accidents Last Year"
          placeholder="e.g. 2"
          type="number"
          @change="
            addToPayload('accidentsLastYear', companyInfo.accidentsLastYear)
          "
        />
      </v-flex>
      <v-flex xs3>
        <CRInput
          id="partnership-settings-on-time-performance"
          v-model="companyInfo.onTimePerformance"
          :disabled="isModeView"
          type="number"
          step="1"
          label="On Time Performance"
          min="0"
          max="100"
          placeholder="e.g. 95"
          @change="
            addToPayload('onTimePerformance', companyInfo.onTimePerformance)
          "
        />
      </v-flex>
      <v-flex xs3></v-flex>

      <v-flex xs3>
        <CRInput
          id="partnership-settings-company-year-founded"
          v-model="companyInfo.yearFounded"
          :disabled="isModeView"
          type="text"
          label="Year Founded"
          placeholder="e.g. 1984"
          @change="addToPayload('yearFounded', companyInfo.yearFounded)"
        />
      </v-flex>
      <v-flex xs3>
        <v-checkbox
          id="partnership-settings-dot-verified"
          v-model="companyInfo.dotVerified"
          :disabled="isModeView"
          label="DOT Verified"
          style="margin-top: 20px"
          hide-details
          @change="addToPayload('dotVerified', companyInfo.dotVerified)"
        />
      </v-flex>

      <v-flex xs3></v-flex>

      <v-flex xs12>
        <label>Company Compliance</label>
        <br />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" style="display: inline-flex; align-items: center;">
              <v-checkbox
                :id="`partnership-settings-compliance-ada`"
                v-model="isADACompliant"
                style="margin-top: 0"
                :disabled=true
                label='ADA Compliant'
              />
            </div>
          </template>
          <span>{{vehicleADACount}} ADA Compliant Vehicles</span>
        </v-tooltip>
        <v-checkbox
          v-for="(compliance, complianceIndex) in companyInfo.companyCompliance"
          :id="`partnership-settings-compliance-${complianceIndex}`"
          :key="`${compliance.amenityType.label}-${complianceIndex}`"
          v-model="compliance.supported"
          style="margin-top: 0"
          :disabled="isModeView"
          :label="compliance.amenityType.label"
        />
      </v-flex>

      <v-flex xs12>
        <label>Company Rules</label>
        <br />
        <v-checkbox
          v-for="(rule, ruleIndex) in companyInfo.companyRules"
          :id="`partnership-settings-rules-${ruleIndex}`"
          :key="`${ruleIndex}`"
          v-model="rule.supported"
          :disabled="isModeView"
          style="margin-top: 0"
          :label="rule.busRuleType.label"
          @change="addToPayload('companyRules', companyInfo.companyRules)"
        />
      </v-flex>

      <v-flex xs12>
        <label>Company Amenities</label>
        <br />
        <v-checkbox
          v-for="(amenity, amenityIndex) in companyInfo.companyAmenities"
          :id="`partnership-settings-amenities-${amenityIndex}`"
          :key="`${amenityIndex}`"
          v-model="amenity.supported"
          style="margin-top: 0"
          :disabled="isModeView"
          :label="amenity.amenityType.label"
        />
      </v-flex>

      <!-- FLEET IMAGE ROW -->
      <v-flex xs12 mb-2>
        <label>Fleet Photo</label>
      </v-flex>
      <v-flex xs12 mb-4>
        <div
          v-if="fleetImage"
          class="fleet-thumbnail"
          @mouseover="handlePhotoHover(fleetImage)"
          @mouseleave="handlePhotoLeave(fleetImage)"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                v-show="fleetImage.hover"
                :id="`partnership-settings-fleet-photo-delete-image`"
                :disabled="isModeView"
                class="delete-photo-icon"
                v-on="on"
                @click="deleteFleetPhoto"
              >
                remove_circle_outline
              </v-icon>
            </template>
            Delete Fleet Photo
          </v-tooltip>
          <img :src="fleetImage.imagePath" />
        </div>
        <div v-else class="add-fleet-thumbnail">
          <input
            :id="`partnership-settings-fleet-image-upload`"
            accept="image/*"
            :disabled="isModeView"
            class="vehicle-image-input"
            type="file"
            @change="addFleetPhoto($event)"
          />
        </div>
      </v-flex>

      <!-- VEHICLES ROW -->
      <v-flex xs12 mb-2>
        <label>Vehicles</label>
      </v-flex>

      <template v-for="(vehicleType, vehicleTypeIndex) in processedPhotos">
        <v-flex :key="`${vehicleTypeIndex}-vehicle-selector`" xs4>
          <CRIcon
            v-if="showDeleteVehicleIcon"
            class="delete-icon"
            :disabled="isModeView"
            style="display: inline-block"
            @click.native="deleteVehicleType(vehicleTypeIndex)"
          >
            trash
          </CRIcon>
          <CRSelect
            :id="`partnership-settings-vehicle-type-selector-${vehicleTypeIndex}`"
            v-model="vehicleType.vehicleTypeId"
            :disabled="isModeView"
            :items="vehicleTypes"
            label="Vehicle Type"
            placeholder="Select Vehicle Type"
            item-text="vehicleTypeName"
            item-value="vehicleTypeId"
            :style="
              showDeleteVehicleIcon
                ? 'display: inline-block; width: calc(100% - 40px);'
                : ''
            "
            @input="updateVehicleIds(vehicleType)"
          />
        </v-flex>
        <v-flex :key="`${vehicleTypeIndex}-photo-container`" xs8>
          <div
            v-for="(photo, photoIndex) in vehicleType.photos"
            :key="photo.id"
            class="vehicle-thumbnail"
            @mouseover="handlePhotoHover(photo)"
            @mouseleave="handlePhotoLeave(photo)"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon
                  :id="`partnership-settings-featured-vehicle-selector-${vehicleTypeIndex}-${photoIndex}`"
                  :disabled="isModeView"
                  class="star-icon"
                  :class="photo.isPrimaryPhoto ? 'selected' : ''"
                  v-on="on"
                  @click="setDefaultPhoto(photo, vehicleType)"
                >
                  stars
                </v-icon>
              </template>

              <span v-if="photo.isPrimaryPhoto">
                This image will be default for this company in CharterUP
              </span>

              <span v-else>
                Click to make this image default for this company in CharterUP
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon
                  :id="`partnership-settings-selected-vehicle-selector-${vehicleTypeIndex}-${photoIndex}`"
                  :disabled="isModeView"
                  class="check-icon"
                  :class="photo.isVisible ? 'selected' : ''"
                  v-on="on"
                  @click="togglePhotoisVisible(photo)"
                >
                  check_circle_outline
                </v-icon>
              </template>

              <span>
                This image will be used in the carousel for this company in
                CharterUP
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  v-show="photo.hover"
                  :id="`partnership-settings-selected-vehicle-delete-image-${vehicleTypeIndex}-${photoIndex}`"
                  :disabled="isModeView"
                  class="delete-photo-icon"
                  :class="photo.isPrimaryPhoto ? 'disabled' : ''"
                  v-on="on"
                  @click="deletePhoto(photo)"
                >
                  remove_circle_outline
                </v-icon>
              </template>

              <span>
                <span v-if="photo.isPrimaryPhoto">
                  Cannot delete primary photo
                </span>
                <span v-else>Remove image</span>
              </span>
            </v-tooltip>

            <v-icon
              v-if="photoIndex > 0"
              v-show="photo.hover"
              :id="`partnership-settings-selected-vehicle-move-up-order-${vehicleTypeIndex}-${photoIndex}`"
              :disabled="isModeView"
              class="increase-order-icon"
              @click="increaseOrder(vehicleTypeIndex, photoIndex)"
            >
              keyboard_arrow_left
            </v-icon>
            <v-icon
              v-if="photoIndex < vehicleType.photos.length - 1"
              v-show="photo.hover"
              :id="`partnership-settings-selected-vehicle-move-down-order-${vehicleTypeIndex}-${photoIndex}`"
              :disabled="isModeView"
              class="decrease-order-icon"
              @click="decreaseOrder(vehicleTypeIndex, photoIndex)"
            >
              keyboard_arrow_right
            </v-icon>
            <img
              :src="photo.imagePath"
              @click="handlePhotoClick(vehicleTypeIndex, photoIndex)"
            />
          </div>
          <div v-if="isModeEdit" class="add-vehicle-thumbnail">
            <input
              :id="`partnership-settings-vehicle-image-upload-${vehicleTypeIndex}`"
              accept="image/*"
              :disabled="isModeView"
              class="vehicle-image-input"
              type="file"
              @change="
                addVehiclePhoto(vehicleType, vehicleType.photos.length, $event)
              "
            />
          </div>
        </v-flex>
      </template>

      <v-flex xs12 class="mb-4" style="margin-top: -16px">
        <a v-if="nextVehicleTypeId !== undefined" @click="addVehicle">
          Add New Vehicle
        </a>
      </v-flex>

      <v-dialog v-model="photoModal.show" persistent>
        <v-card>
          <v-card-text>
            <img
              :src="photoModal.photo.imagePath"
              style="max-width: 100%; max-height: 700px"
            />

            <v-icon class="previous" @click="previousPhoto">
              keyboard_arrow_left
            </v-icon>

            <v-icon class="next" @click="nextPhoto">
              keyboard_arrow_right
            </v-icon>

            <v-icon class="close" @click="photoModal.show = false">
              close
            </v-icon>
          </v-card-text>

          <v-card-text style="padding: 8px 20px !important; text-align: center">
            <CRInput
              ref="photoLabel"
              v-model="photoModal.photo.label"
              type="text"
              :disabled="!photoModal.editingLabel"
              placeholder="Photo title here"
            />
            <CRIcon
              v-show="isModeEdit && !photoModal.editingLabel"
              class="edit-label"
              @click.native="startEditLabel"
            >
              edit
            </CRIcon>
            <CRIcon
              v-show="isModeEdit && photoModal.editingLabel"
              class="edit-label"
              @click.native="saveEditLabel"
            >
              check
            </CRIcon>
            <CRIcon
              v-show="isModeEdit && photoModal.editingLabel"
              class="edit-label"
              @click=.native"cancelEditLabel"
            >
              close
            </CRIcon>
          </v-card-text>

          <v-card-actions v-if="isModeEdit">
            <v-btn
              outline
              class="delete"
              :disabled="isModalPhotoPrimary"
              @click="deletePhotoFromModal"
            >
              Delete Photo
            </v-btn>

            <v-spacer />

            <v-btn
              outline
              class="marketplace"
              :disabled="isModalPhotoPrimary"
              @click="photoModal.photo.isVisible = !photoModal.photo.isVisible"
            >
              {{
                isModalPhotoVisible
                  ? 'Remove From Marketplace'
                  : 'Add to Marketplace'
              }}
            </v-btn>

            <v-btn
              outline
              class="default"
              :disabled="isModalPhotoPrimary"
              @click="setDefaultPhoto(photoModal.photo, vehicleType)"
            >
              Make Default
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'
import * as logger from '@/utils/logger'
import { mapActions } from 'vuex'
import markets from '@/services/markets'
import PartnershipSettingsPDFDragAndDrop from '@/components/PartnershipSettingsPDFDragAndDrop.vue'
import companies from '@/services/companies'
import { deepClone } from '@/utils/deepClone'
import { environmentPrefix } from '@/utils/env'
import { reverse } from '@/utils/string'
import { PartnerTypeId } from '@/utils/enum'
import { getOnboardingStatusTypes } from '@/services/types'

const RESELLER_TYPE_IDS = [
  PartnerTypeId.GoldReseller,
  PartnerTypeId.SilverReseller,
  PartnerTypeId.BronzeReseller,
]
const PARTNER_TYPE_IDS = [
  ...RESELLER_TYPE_IDS,
  PartnerTypeId.Platinum,
  PartnerTypeId.Gold,
  PartnerTypeId.Silver,
  PartnerTypeId.Bronze,
]
const RESELLER_KEYS = new Set(['gold_reseller_brand', 'silver_reseller_brand', 'bronze_reseller_brand'])

export default {
  components: {
    PartnershipSettingsPDFDragAndDrop,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'view',
    },
    displayEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      brandLicensees: [
        {
          id: 10000,
          label: 'Shofur',
          isPartner: false,
        },
        {
          id: 10001,
          label: 'GOGO',
          isPartner: false,
        },
        {
          id: null,
          label: 'None',
          isPartner: false,
        },
      ],
      partnerTypesList: [],
      onboardingStatusTypes: [],
      charterUPOperatingStatusTypes: [],
      vehicleTypes: [],
      rules: {
        partnerName: [
          (v) =>
            !!v ||
            [7, 8, 9, 10].includes(this.companyInfo?.partnerTypeId) ||
            'Partner name is required',
        ],
        outOfMarketPartnerName: [
          (v) =>
            !!v ||
            [7, 8, 9, 10].includes(this.companyInfo?.partnerTypeId) ||
            'Out of market partner name is required',
        ],
        partnerPhone: [
          (v) =>
            !!v ||
            [7, 8, 9, 10].includes(this.companyInfo?.partnerTypeId) ||
            'Partner phone is required',
          (v) =>
            (!!v && v.length === 10) ||
            !!v ||
            [7, 8, 9, 10].includes(this.companyInfo?.partnerTypeId) ||
            'Partner phone must be a valid number',
        ],
        ratingsStars: [
          (v) => v >= 0 || 'Rating stars cannot be less than 0',
          (v) => v <= 5 || 'Rating stars cannot be greater than 5',
        ],
        ratingsCount: [
          (v) =>
            !!v ||
            [7, 8, 9, 10].includes(this.companyInfo?.partnerTypeId) ||
            'Rating count is required',
          (v) => v >= 0 || 'Rating count cannot be less than 0',
        ],
        reviewerName: [(v) => !!v || 'Reviewer name is required'],
        reviewPickupDatetime: [(v) => !!v || 'Review pickup date is required'],
        reviewPickupLocation: [
          (v) => !!v || 'Review pickup location is required',
        ],
        reviewerRating: [
          (v) => !!v || 'Reviewer rating is required',
          (v) => v >= 0 || 'Reviewer rating cannot be less than 0',
          (v) => v <= 5 || 'Reviewer rating cannot be greater than 5',
        ],
        reviewDescription: [
          (v) => !!v || 'Review description is required',
          (v) =>
            (v && v.length <= 1000) ||
            'Review description must have 1000 characters or fewer',
        ],
        publicSlug: [
          (v) =>
            !v ||
            // eslint-disable-next-line no-useless-escape
            /^[a-zA-Z0-9\-]+$/.test(v) ||
            'Public Slug can only contain letters, numbers, and hyphens',
        ],
        quality: [
          (v) => v <= 1 || 'Operator Quality cannot be greater than 1',
          (v) => v >= 0 || 'Operator Quality cannot be less than 0',
          (v) => {
            if (!v) {
              return true
            }
            const decimalCount = (v.toString().split('.')[1] || '').length
            return (
              decimalCount <= 2 ||
              'Input cannot have more than 2 decimal places'
            )
          },
        ],
      },
      markets: [],
      nextMarketId: 1,
      nextVehicleTypeId: 1,
      companyInfo: [],
      insurancePdf: null,
      processedPhotos: [],
      deletedReviews: [],
      deletedPhotos: [],
      payload: {},
      valid: true,
      photoModal: {
        show: false,
        vehicleTypeIndex: 0,
        photoIndex: 0,
        photo: {
          label: null,
          imagePath: null,
          isVisible: null,
        },
        imagePath: null,
        editingLabel: false,
      },
      sendMarketplaceEmails: null,
      fleetImage: null,
      originalPhotoLabel: null,
      vehicleADACount: 0,
    }
  },
  computed: {
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    partnerTypeOptions() {
      if (RESELLER_TYPE_IDS.includes(this.companyInfo?.partnerTypeId)) {
        return this.partnerTypes.resellers
      }

      return this.partnerTypes.nonresellers
    },
    insurancePdfExists() {
      return !!this.companyInfo.insuranceUrl
    },
    brandLicenseOptions() {
      const partnerTypeId = this.companyInfo?.partnerTypeId
      const options = {
        partnerTypeDropdownDisabled: false,
        brandLicenseDropdownDisabled: false,
        brandLicenseDropdownVisible: true,
        isPreferredDisabled: false,
        brandChoices: [
          {
            id: 10000,
            label: 'Shofur',
            isPartner: false,
          },
          {
            id: 10001,
            label: 'GOGO',
            isPartner: false,
          },
          {
            id: 28150,
            label: 'National',
            isPartner: false,
          },
          {
            id: null,
            label: 'None',
            isPartner: false,
          },
        ],
      }
      if (RESELLER_TYPE_IDS.includes(partnerTypeId)) {
        options.partnerTypeDropdownDisabled = true
        options.brandLicenseDropdownDisabled = true
        options.brandLicenseDropdownVisible = false
      } else if (partnerTypeId === PartnerTypeId.Gold) {
        options.brandChoices = [
          {
            id: 10000,
            label: 'Shofur',
            isPartner: false,
          },
          {
            id: 10001,
            label: 'GOGO',
            isPartner: false,
          },
          {
            id: 28150,
            label: 'National',
            isPartner: false,
          },
        ]
      } else if (partnerTypeId === PartnerTypeId.Silver) {
        options.brandLicenseDropdownDisabled = true
        options.brandChoices = [
          {
            id: null,
            label: 'None',
            isPartner: false,
          },
        ]
      }
      return options
    },
    isModalPhotoPrimary() {
      return this.photoModal?.photo?.isPrimaryPhoto
    },
    isModalPhotoVisible() {
      return this.photoModal?.photo?.isVisible
    },
    showAddNewMarket() {
      return this.companyInfo?.dbaNameList?.length < this.markets?.length
    },
    showDeleteMarketIcon() {
      return this.companyInfo?.dbaNameList?.length > 1
    },
    showDeleteVehicleIcon() {
      return this.processedPhotos?.length > 1
    },
    showDeleteReviewIcon() {
      return this.companyInfo?.companyReviews?.length > 1
    },
    showPublicSiteControls() {
      return PARTNER_TYPE_IDS.includes(this.companyInfo?.partnerTypeId)
    },
    partnerTypes() {
      const resellers = this.partnerTypesList.filter(partnerType => RESELLER_KEYS.has(partnerType.key))
      const nonresellers = this.partnerTypesList.filter(partnerType => !RESELLER_KEYS.has(partnerType.key))
      return {
        resellers,
        nonresellers
      }
    },
    isADACompliant() {
      return this.vehicleADACount > 0
    }
  },
  watch: {
    'companyInfo.partnerTypeId'() {
      if (this.companyInfo.partnerTypeId === PartnerTypeId.Silver) {
        this.companyInfo.brandLicenseeIds = []
        this.companyInfo.brandLicenseeId = null
        this.changeBrandLicense()
      }
    },
  },
  async created() {
    this.companyInfo = Object.assign({}, this.companyInfo, this.company)
    if (!this.companyInfo.outOfMarketDbaName) {
      this.processOutOfMarketDbaName()
    }
    this.processAmenities()
    this.getPartnerTypes()
  },
  async mounted() {
    this.sendMarketplaceEmails = !this.companyInfo.sendMarketplaceEmails
    this.getCharterUPOperatingStatuses()
    this.getOnboardingStatuses()
    this.getCompanyVehicleAmenities()
    await this.loadData()
  },
  beforeDestroy() {
    this.reinsertCompliance()
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    async getOnboardingStatuses() {
      const result = await getOnboardingStatusTypes({ pageSize: -1 })
      this.onboardingStatusTypes = result?.data?.resultList
    },
    async getPartnerTypes() {
      const partnerTypeResults = await this.$store
        .dispatch('types/getPartnerTypes')
        .catch((e) => {
          console.error(e)
        })
      this.partnerTypesList = partnerTypeResults.data.resultList
    },
    async getCharterUPOperatingStatuses() {
      const result = await companies.getCharterUPOperatingStatuses()
      this.charterUPOperatingStatusTypes = result?.data?.charterUpOperatingStatuses
    },
    async loadData() {
      this.processCompanyRating()
      this.processOperatorQuality()
      await this.getVehicleTypes()
      await this.getMarkets()
      this.processMarkets()
      this.processOutOfMarketDbaName()
      this.processPhotos()
      this.processBrandLicensees()
      this.processDescriptions()
    },
    async updatePage() {
      this.processedPhotos = []
      this.deletedReviews = []
      this.deletedPhotos = []
      this.payload = {}
      const companyResponse = await companies.getCompany(this.company.companyId)
      this.companyInfo = Object.assign(
        {},
        this.companyInfo,
        companyResponse.data.company
      )
      this.sendMarketplaceEmails = !this.companyInfo.sendMarketplaceEmails
      await this.loadData()
      this.processAmenities()
    },
    async getVehicleTypes() {
      const vehicleTypeResponse = await this.$store.dispatch(
        'types/getVehicleTypes'
      )
      vehicleTypeResponse.data.resultList.forEach((vehicleType) => {
        vehicleType.disabled = false
        vehicleType.vehicleTypeId = vehicleType.id
        vehicleType.vehicleTypeName = vehicleType.label
        delete vehicleType.id
        delete vehicleType.label
      })
      this.vehicleTypes = vehicleTypeResponse.data.resultList
    },
    async getMarkets() {
      const marketsResponse = await markets.tableView({
        pageSize: -1,
      })
      this.markets = marketsResponse.data.resultList
    },
    processOperatorQuality() {
      if (!this.companyInfo.quality) {
        return
      }
      this.companyInfo.quality = this.companyInfo.quality.toFixed(2)
    },
    processMarkets() {
      this.markets.forEach((market) => {
        market.disabled = false
      })
      if (this.companyInfo.dbaNameList?.length > 0) {
        this.companyInfo.dbaNameList.forEach((market) => {
          const result = this.markets.find(
            (target) => target.marketId === market.marketId
          )
          result.disabled = true
        })
        this.nextMarketId = this.markets.find(
          (element) => element.disabled === false
        )?.marketId
      } else {
        this.addMarket()
      }
    },
    processOutOfMarketDbaName() {
      if (!this.companyInfo.outOfMarketDbaName) {
        const newOutOfMarketDbaName = {
          companyId: this.companyInfo.companyId,
          id: null,
          name: '',
        }
        this.companyInfo.outOfMarketDbaName = newOutOfMarketDbaName
      }
    },
    toggleSendMarketplaceEmails() {
      this.companyInfo.sendMarketplaceEmails = !this.sendMarketplaceEmails
      this.addToPayload(
        'sendMarketplaceEmails',
        this.companyInfo.sendMarketplaceEmails
      )
    },
    processCompanyRating() {
      if (!this.companyInfo.companyRating) {
        this.companyInfo.companyRating = null
      }
    },
    processBrandLicensees() {
      this.companyInfo.brandLicenseeId = this.companyInfo.brandLicenseeIds?.[0]
    },
    changeBrandLicense() {
      this.companyInfo.brandLicenseeIds = []
      if (this.companyInfo.brandLicenseeId !== null) {
        this.companyInfo.brandLicenseeIds.push(this.companyInfo.brandLicenseeId)
      }
    },
    processAmenities() {
      const amenityTypeKeys = ['cdc', 'dod', 'spab']
      const compliance = this.companyInfo.companyAmenities.filter((amenity) =>
        amenityTypeKeys.includes(amenity.amenityType.key)
      )
      this.companyInfo.companyCompliance = deepClone(compliance)
      this.companyInfo.companyAmenities = this.companyInfo.companyAmenities.filter(
        (amenity) => !amenityTypeKeys.includes(amenity.amenityType.key) && amenity.amenityType.key !== 'ada'
      )
    },
    async getCompanyVehicleAmenities() {
      const vehicleAmenitiesResponse = await companies.getCompanyVehicleAmenities(this.companyInfo.companyId)
      const companyVehicleAmenities = vehicleAmenitiesResponse.data.companyVehicleAmenities
      this.vehicleADACount = companyVehicleAmenities.filter(cva => cva.vehicleAmenities.some(amenity => amenity.amenityName === "ADA Compliant")).length;
    },
    processDescriptions() {
      if (this.companyInfo.description === null) {
        this.companyInfo.description = ''
      }
      if (this.companyInfo.cancellationPolicy === null) {
        this.companyInfo.cancellationPolicy = ''
      }
      if (this.companyInfo.insurancePolicy === null) {
        this.companyInfo.insurancePolicy = ''
      }
    },
    reinsertCompliance() {
      if (this.companyInfo.companyCompliance) {
        this.companyInfo.companyCompliance.forEach((complianceType) => {
          if (complianceType && complianceType.amenityType) {
            this.companyInfo.companyAmenities.push(complianceType)
          }
        })
      }
    },
    processPhotos() {
      if (!this.companyInfo.vehiclePhotoList.length) {
        return
      }
      const fleetPhoto = this.companyInfo.vehiclePhotoList.find(
        (photo) => photo.isActive && photo.isFleetImage
      )
      if (fleetPhoto) {
        this.fleetImage = fleetPhoto
      }
      const vehicleImages = this.companyInfo.vehiclePhotoList.filter(
        (photo) => photo.isActive && !photo.isFleetImage
      )
      if (!vehicleImages.length) {
        return
      }
      const processedPhotos = {}
      vehicleImages.forEach((photo) => {
        photo.isNew = false
        const type = photo.vehicleTypeId
        photo.hover = false
        if (!(type in processedPhotos)) {
          processedPhotos[type] = []
        }
        processedPhotos[type].push(photo)
      })
      const photosList = Object.values(processedPhotos)
      const typesList = Object.keys(processedPhotos)
      for (let i = 0; i < typesList.length; i++) {
        photosList[i] = photosList[i].sort((a, b) => {
          return a.orderIndex - b.orderIndex
        })
        photosList[i].forEach((photo, photoIndex) => {
          photo.orderIndex = photoIndex
        })
        const vehicleTypeObject = {
          vehicleTypeId: parseInt(typesList[i]),
          photos: photosList[i],
        }
        this.processedPhotos.push(vehicleTypeObject)
      }

      this.photoModal.photo = this.processedPhotos[
        this.photoModal.vehicleTypeIndex
      ].photos[this.photoModal.photoIndex]
      this.updateVehicleTypeList()
    },
    increaseOrder(vehicleTypeIndex, photoIndex) {
      const vehiclePhotos = this.processedPhotos[vehicleTypeIndex].photos
      vehiclePhotos[photoIndex].orderIndex--
      vehiclePhotos[photoIndex - 1].orderIndex++
      ;[vehiclePhotos[photoIndex - 1], vehiclePhotos[photoIndex]] = [
        vehiclePhotos[photoIndex],
        vehiclePhotos[photoIndex - 1],
      ]
      this.$forceUpdate()
    },
    decreaseOrder(vehicleTypeIndex, photoIndex) {
      const vehiclePhotos = this.processedPhotos[vehicleTypeIndex].photos
      vehiclePhotos[photoIndex].orderIndex++
      vehiclePhotos[photoIndex + 1].orderIndex--
      ;[vehiclePhotos[photoIndex + 1], vehiclePhotos[photoIndex]] = [
        vehiclePhotos[photoIndex],
        vehiclePhotos[photoIndex + 1],
      ]
      this.$forceUpdate()
    },
    updateVehicleIds(vehicleType) {
      vehicleType.photos.forEach((photo) => {
        photo.vehicleTypeId = vehicleType.vehicleTypeId
      })
      this.updateVehicleTypeList()
    },
    updateVehicleTypeList() {
      this.vehicleTypes.forEach((vehicleType) => {
        vehicleType.disabled = false
      })
      this.processedPhotos.forEach((vehicleType) => {
        const result = this.vehicleTypes.find(
          (target) => target.vehicleTypeId === vehicleType.vehicleTypeId
        )
        result.disabled = true
      })
      this.nextVehicleTypeId = this.vehicleTypes.find(
        (element) => element.disabled === false
      )?.vehicleTypeId
    },
    addVehicle() {
      if (!this.isModeView) {
        const newVehicleType = {
          photos: [],
          vehicleTypeId: this.nextVehicleTypeId,
        }
        this.processedPhotos.push(newVehicleType)
        this.updateVehicleTypeList()
      }
    },
    deletePhoto(photo) {
      if (!photo.isPrimaryPhoto) {
        photo.isActive = false
        photo.isPrimaryPhoto = false
        photo.isVisible = false
        if (!photo.isNew) {
          this.deletedPhotos.push(photo)
        }
        const vehicleGroup = this.processedPhotos.find(
          (element) => element.vehicleTypeId === photo.vehicleTypeId
        )
        const indexResult = vehicleGroup.photos.indexOf(photo)
        vehicleGroup.photos.splice(indexResult, 1)
      }
    },
    deleteFleetPhoto() {
      this.fleetImage.isActive = false
      this.fleetImage.isPrimaryPhoto = false
      this.fleetImage.isVisible = false
      if (!this.fleetImage.isNew) {
        this.deletedPhotos.push(this.fleetImage)
      }
      this.fleetImage = null
    },
    deletePhotoFromModal() {
      this.deletePhoto(this.photoModal.photo)
      const photosLength = this.processedPhotos[
        this.photoModal.vehicleTypeIndex
      ].photos.length
      if (this.photoModal.photoIndex < photosLength) {
        this.setModalPhotoFromIndex()
      } else if (
        this.photoModal.photoIndex === photosLength &&
        photosLength !== 0
      ) {
        this.photoModal.photoIndex--
        this.setModalPhotoFromIndex()
      } else {
        this.photoModal.show = false
      }
    },
    deleteVehicleType(index) {
      const deletedVehicle = this.processedPhotos.splice(index, 1)[0]
      deletedVehicle.photos.forEach((photo) => {
        photo.isActive = false
        photo.isPrimaryPhoto = false
        photo.isVisible = false
        if (!photo.isNew) {
          this.deletedPhotos.push(photo)
        }
      })
      this.updateVehicleTypeList()
    },
    handlePhotoHover(photo) {
      photo.hover = true && !this.isModeView
      this.$forceUpdate()
    },
    handlePhotoLeave(photo) {
      photo.hover = false
      this.$forceUpdate()
    },
    handlePhotoClick(vehicleTypeIndex, photoIndex) {
      this.photoModal.show = true
      this.photoModal.vehicleTypeIndex = vehicleTypeIndex
      this.photoModal.photoIndex = photoIndex
      this.setModalPhotoFromIndex()
    },
    previousPhoto() {
      if (this.photoModal.photoIndex > 0) {
        this.photoModal.photoIndex--
        this.setModalPhotoFromIndex()
      }
    },
    nextPhoto() {
      if (
        this.photoModal.photoIndex <
        this.processedPhotos[this.photoModal.vehicleTypeIndex].photos.length - 1
      ) {
        this.photoModal.photoIndex++
        this.setModalPhotoFromIndex()
      }
    },
    setModalPhotoFromIndex() {
      this.photoModal.photo = this.processedPhotos[
        this.photoModal.vehicleTypeIndex
      ].photos[this.photoModal.photoIndex]
    },
    addMarket() {
      if (
        !this.isModeView &&
        this.companyInfo.dbaNameList.length < this.markets.length
      ) {
        const newMarket = {
          companyId: this.companyInfo.companyId,
          id: null,
          marketId: this.nextMarketId,
          name: '',
        }
        this.companyInfo.dbaNameList.push(newMarket)
        this.processMarkets()
      }
    },
    deleteMarket(index) {
      if (this.companyInfo.dbaNameList.length > 1) {
        this.companyInfo.dbaNameList.splice(index, 1)
      }
      this.processMarkets()
    },
    async addVehiclePhoto(vehicleType, orderIndex, event) {
      const timestamp = this.getTimestamp()

      const newImageObject = {
        companyId: this.companyInfo.companyId,
        createdOn: timestamp,
        id: null,
        imagePath: null,
        isPrimaryPhoto: vehicleType.photos.length === 0,
        isVisible: true,
        updatedOn: timestamp,
        vehicleId: null,
        vehicleTypeId: vehicleType.vehicleTypeId,
        isNew: true,
        isFleetImage: false,
        label: null,
        orderIndex,
      }

      const file = event.target.files[0]
      if (file.length !== 0) {
        const link = URL.createObjectURL(file)
        newImageObject.imagePath = link
        newImageObject.file = file
        newImageObject.filename = `${
          this.companyInfo.companyId
        }_${new Date().getTime()}_${file.name}`

        vehicleType.photos.push(newImageObject)
      }
    },
    async addFleetPhoto(event) {
      const timestamp = this.getTimestamp()

      const newImageObject = {
        companyId: this.companyInfo.companyId,
        createdOn: timestamp,
        id: null,
        imagePath: null,
        isPrimaryPhoto: false,
        isVisible: true,
        updatedOn: timestamp,
        vehicleId: null,
        vehicleTypeId: null,
        isNew: true,
        orderIndex: null,
        isFleetImage: true,
      }

      const file = event.target.files[0]
      if (file.length !== 0) {
        const link = URL.createObjectURL(file)
        newImageObject.imagePath = link
        newImageObject.file = file
        newImageObject.filename = `${
          this.companyInfo.companyId
        }_${new Date().getTime()}_${file.name}`

        this.fleetImage = newImageObject
      }
    },
    setDefaultPhoto(photoToBeDefault, vehicleType) {
      if (photoToBeDefault.isPrimaryPhoto !== true) {
        vehicleType.photos.forEach((photo) => {
          photo.isPrimaryPhoto = false
        })
        photoToBeDefault.isPrimaryPhoto = true
        photoToBeDefault.isVisible = true
      }
    },
    togglePhotoisVisible(photo) {
      if (!photo.isPrimaryPhoto) {
        photo.isVisible = !photo.isVisible
      }
    },
    deleteReview(index) {
      const deletedReview = this.companyInfo.companyReviews.splice(index, 1)[0]
      if (deletedReview.changeKey !== 'create') {
        this.setChangeKey(deletedReview, 'delete')
        this.deletedReviews.push(deletedReview)
      }
    },
    addReview() {
      if (!this.isModeView) {
        const timestamp = this.getTimestamp()
        const newReview = {
          companyId: this.companyInfo.companyId,
          createdOn: timestamp,
          customerId: null,
          id: null,
          rating: null,
          reviewDescription: '',
          updatedOn: timestamp,
          pickupDatetime: null,
          pickupLocation: null,
          changeKey: 'create',
        }
        this.companyInfo.companyReviews.push(newReview)
      }
    },
    setReviewChangeKey(review, value) {
      this.setChangeKey(review, 'update')
      review.updatedOn = this.getTimestamp()
    },
    addToPayload(key, value) {
      this.payload[key] = value
    },
    isFormValid() {
      return this.$refs.partnershipSettingsForm.validate()
    },
    async processPdf() {
      // upload the pdf to the uploadInsurancePdf endpoint
      if (this.insurancePdf !== null) {
        try {
          const params = {
            companyId: this.companyInfo.companyId,
            payload: this.insurancePdf,
          }
          await companies.uploadInsurancePdf(params)
          logger.info('Successfully uploaded insurance pdf!')
        } catch (e) {
          logger.warn('Error uploading insurance pdf')
          logger.warn(e)
          this.showAlert({
            message: 'Error uploading insurance pdf',
            type: 'error',
          })
        }
      }
    },
    async processPayload() {
      this.payload.partnerPhone = this.companyInfo.partnerPhone
      this.payload.brandLicenseeIds = this.companyInfo.brandLicenseeIds
      this.payload.dbaNameList = this.companyInfo.dbaNameList
      this.payload.outOfMarketDbaName = this.companyInfo.outOfMarketDbaName
      this.payload.companyId = this.companyInfo.companyId
      this.payload.publicSiteEnabled = this.companyInfo.publicSiteEnabled
      this.payload.companyRules = this.companyInfo.companyRules
      this.payload.quality = this.companyInfo.quality
      const adaCompliance = this.companyInfo.companyCompliance?.find(
        (item) => item.amenityType.key === 'ada' && item.supported === true
      )
      const spabCompliance = this.companyInfo.companyCompliance?.find(
        (item) => item.amenityType.key === 'spab' && item.supported === true
      )
      this.payload.ada = !!adaCompliance
      this.payload.spab = !!spabCompliance

      // process vehicles back to original format
      const updatedVehiclePhotoList = []
      this.processedPhotos.forEach((type) => {
        type.photos.forEach(async (photo) => {
          if (photo.isNew) {
            const form = new FormData()
            form.append('file', photo.file, photo.filename)
            form.append('id', photo.vehicleTypeId)
            form.append('visible', photo.isVisible)
            form.append('primary', photo.isPrimaryPhoto)
            form.append('fleetPhoto', photo.isFleetImage)
            form.append('orderIndex', photo.orderIndex)
            if (photo.label) {
              form.append('label', photo.label)
            }
            try {
              const params = {
                payload: form,
                companyId: photo.companyId,
              }
              await this.$store.dispatch(
                'vehicles/uploadVehiclePhotosCharterUp',
                params
              )
              logger.info('Successfully uploaded photo!')
            } catch (e) {
              logger.warn('Error uploading photo')
              logger.warn(e)
            }
          } else {
            updatedVehiclePhotoList.push(photo)
          }
        })
      })

      if (this.fleetImage?.isNew) {
        const form = new FormData()
        form.append('file', this.fleetImage.file, this.fleetImage.filename)
        form.append('visible', this.fleetImage.isVisible)
        form.append('primary', this.fleetImage.isPrimaryPhoto)
        form.append('fleetPhoto', this.fleetImage.isFleetImage)
        try {
          const params = {
            payload: form,
            companyId: this.fleetImage.companyId,
          }
          await this.$store.dispatch(
            'vehicles/uploadVehiclePhotosCharterUp',
            params
          )
          logger.info('Successfully uploaded photo!')
        } catch (e) {
          logger.warn('Error uploading photo')
          logger.warn(e)
        }
      } else if (this.fleetImage) {
        updatedVehiclePhotoList.push(this.fleetImage)
      }

      // add deleted photos back to the photo list
      this.deletedPhotos.forEach((photo) => {
        updatedVehiclePhotoList.push(photo)
      })
      if (updatedVehiclePhotoList.length > 0) {
        this.payload.vehiclePhotoList = updatedVehiclePhotoList
      }

      // add all updated, created, or deleted reviews to the payload
      const processedReviews = []

      this.deletedReviews.forEach((review) => {
        processedReviews.push(review)
      })

      if (this.companyInfo.companyReviews) {
        this.companyInfo.companyReviews.forEach((review) => {
          if (
            review?.changeKey === 'update' ||
            review?.changeKey === 'create'
          ) {
            if (review?.pickupDatetime?.length === 10) {
              review.pickupDatetime = `${review.pickupDatetime}T12:00:00.000+0000`
            }
            processedReviews.push(review)
          }
        })
      }

      if (processedReviews.length > 0) {
        this.payload.companyReviews = processedReviews
      }

      this.reinsertCompliance()
      this.addToPayload('companyAmenities', this.companyInfo.companyAmenities)
      this.addToPayload('isPreferred', this.companyInfo.isPreferred)
      return this.payload
    },
    getTimestamp() {
      let timestamp = DateTime.local().toUTC().toISO()
      timestamp = `${timestamp.substring(0, timestamp.length - 1)}+0000`
      return timestamp
    },
    setChangeKey(object, keyValue) {
      if (keyValue === 'delete' || object.changeKey !== 'create') {
        object.changeKey = keyValue
      }
    },
    startEditLabel() {
      this.photoModal.editingLabel = true
      this.originalPhotoLabel = deepClone(this.photoModal.photo?.label)
      this.$forceUpdate()
    },
    saveEditLabel() {
      this.photoModal.editingLabel = false
    },
    cancelEditLabel() {
      this.photoModal.photo.label = deepClone(this.originalPhotoLabel)
      this.photoModal.editingLabel = false
    },
    openInCharterUP(marketId) {
      const companyId = this.companyInfo.companyId
      window.open(
        `https://${environmentPrefix()}.charterup.com/provider/${companyId}/${marketId}`
      )
    },
    openPublicPage() {
      const hash = this.companyInfo.hash
      const host =
        environmentPrefix() === 'dev' ? 'dev.charterup-qa' : 'charterup'
      const query = !this.companyInfo.publicSiteEnabled
        ? `?override=${reverse(hash)}`
        : ''
      window.open(`https://${host}.com/companies/${hash}${query}`)
    },
    cancelEdit(event) {
      event.preventDefault()
      this.updatePage()
      this.$emit('cancel-edit')
    },
    toggleEdit(event) {
      event.preventDefault()
      this.$emit('toggle-edit')
    },
    submit(event) {
      event.preventDefault()
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss" scoped>
.flex {
  padding: 0 16px;
}
.v-dialog__content {
  ::v-deep .v-dialog.v-dialog--active.v-dialog--persistent {
    display: inline-block;
    width: unset;
  }
}

.v-icon--disabled {
  opacity: 1;
}

.v-input--selection-controls {
  &.v-input--checkbox {
    display: inline-block;
  }
  ::v-deep &:not(.v-input--hide-details) .v-input__slot {
    margin-bottom: 0;
  }
}
::v-deep label.v-label.theme--light {
  white-space: nowrap;
}

.fleet-thumbnail,
.add-fleet-thumbnail,
.vehicle-thumbnail,
.add-vehicle-thumbnail {
  height: 66px;
  width: 66px;
  margin-top: 12px;
  display: inline-flex;
  cursor: pointer !important;
  position: relative;
  border-radius: 10px;
}

.fleet-thumbnail {
  border: 1px solid $primary;
  img {
    height: 66px;
    width: 66px;
    object-fit: cover;
    border-radius: 10px;
  }
  .delete-photo-icon {
    height: 20px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    color: $white;
    background-color: $red;
    bottom: -8px;
    right: -8px;
    &.disabled {
      background-color: $blue-dull;
    }
  }
}
.add-fleet-thumbnail {
  border: 1px solid $black;
}

.vehicle-thumbnail {
  margin-left: 24px;
  border: 1px solid $primary;

  img {
    height: 66px;
    width: 66px;
    object-fit: cover;
    border-radius: 10px;
  }

  .star-icon,
  .check-icon,
  .delete-photo-icon {
    height: 20px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
  }

  .star-icon {
    color: $white !important;
    background-color: $blue-dull;
    top: -8px;
    left: -8px;
    &.selected {
      background-color: $primary;
    }
  }

  .check-icon {
    color: $blue-dull;
    background-color: $white;
    top: -8px;
    right: -8px;
    &.selected {
      color: $primary !important;
    }
  }

  .delete-photo-icon {
    color: $white;
    background-color: $red;
    bottom: -8px;
    right: -8px;
    &.disabled {
      background-color: $blue-dull;
    }
  }
  .increase-order-icon,
  .decrease-order-icon {
    cursor: pointer;
    position: absolute;
    bottom: -24px;
  }

  .increase-order-icon {
    left: 0;
  }
  .decrease-order-icon {
    right: 0;
  }
}

.add-vehicle-thumbnail {
  margin-left: 24px;
  border: 1px solid $black;
}

.vehicle-image-input {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 66px;
  height: 66px;
  cursor: pointer;
  opacity: 0;
}

.v-dialog {
  .v-card {
    max-width: 500px !important;
    .v-card__text {
      padding: 0;
      position: relative;
      .v-icon {
        color: rgba($white, 0.75);
        background: rgba($black-base, 0.25);
        border-radius: 50%;
        &.previous {
          position: absolute;
          top: 50%;
          left: 25px;
          font-size: 65px;
        }
        &.next {
          position: absolute;
          top: 50%;
          right: 25px;
          font-size: 65px;
        }
        &.close {
          position: absolute;
          top: 15px;
          right: 15px;
          font-size: 32.5px;
        }
      }
    }
    .v-card__actions {
      padding: 16px;
      .v-btn {
        &.delete {
          color: $red;
        }
        &.marketplace {
          color: $primary;
        }
        &.default {
          color: $primary;
        }
      }
    }
  }
}

.delete-icon {
  color: $gray-mid-light !important;
  margin: 0 16px -6px 0 !important;

  &:hover {
    color: $error !important;
  }
}
::v-deep .v-input {
  .v-input__control {
    .v-input__slot {
      label {
        color: $gray-dark !important;
        font-size: 14px !important;
      }
    }
  }
}

.edit-label {
  margin: 0 0 -9px 8px !important;
  cursor: pointer;
  color: $primary;
}

[contenteditable] {
  max-width: 100%;
  outline: 0px solid transparent;
  &:focus {
    border-bottom: 2px solid $primary;
  }
}

::v-deep label.v-label.theme--light {
  width: 140px;
}
</style>
