<template>
  <v-layout column class="charterup-cont">
    <v-layout row class="charterup-logo">
      <div class="logo-container">
        <CharterUpLogo style="width: 307px" />
      </div>
      <v-icon
        id="charterup-sync-workflow-exit"
        class="exit-square"
        @click="close"
      >
        close
      </v-icon>
    </v-layout>
    <component :is="currentMode" :close="close" />
  </v-layout>
</template>
<script>
import CharterUpLogo from '@/components/CharterUpLogo.vue'
import CharterUpSettings from './CharterUpSettings.vue'

import { authComputed } from '@/state/helpers'

export default {
  components: {
    CharterUpLogo,
    CharterUpSettings,
  },
  props: {
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentMode: CharterUpSettings,
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.charterup-cont {
  width: 100%;
}

.charterup-logo {
  background-color: $blue;
  justify-content: space-between;
  min-height: 100px;
  align-items: center;
}

.charterup-body {
  background-color: $white;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 35px;
  margin-left: 0%;
}

.exit-square {
  color: $white;
  font-size: 32px;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
}
</style>
