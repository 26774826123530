<template>
  <v-container class="charterup-settings">
    <v-stepper v-model="step">
      <v-stepper-header v-if="!linked" id="charterup-sync-stepperheader">
        <v-stepper-step :complete="step > 1" step="1"></v-stepper-step>

        <v-divider
          :class="`${step > 1 ? 'complete' : 'incomplete'}`"
        ></v-divider>

        <v-stepper-step :complete="step > 2" step="2"></v-stepper-step>

        <v-divider
          :class="`${step > 2 ? 'complete' : 'incomplete'}`"
        ></v-divider>

        <v-stepper-step step="3"></v-stepper-step>
      </v-stepper-header>

      <v-stepper-items style="height: 90%">
        <v-stepper-content style="height: 100%" step="1">
          <v-layout style="height: 100%; justify-content: space-between" column>
            <div>
              <h3 style="margin-bottom: 20px">
                Link your CharterUP Account to Coachrail
              </h3>
              <v-form ref="form">
                <CRInput
                  id="charterup-sync-provider-company-name"
                  v-model="companyName"
                  :floatinglabel="'Company Name'"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'Company Name Is Required',
                      error: 'Company Name Is Required',
                    }),
                  ]"
                />
                <CRInput
                  v-model="dotNumber"
                  :disabled="true"
                  :floatinglabel="'DOT / PUC Number'"
                />
                <CRInput
                  id="charterup-sync-provider-email"
                  v-model="email"
                  :floatinglabel="'Company Email'"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'Email Is Required',
                      error: 'Email Is Required',
                    }),
                  ]"
                />
              </v-form>
            </div>
            <v-btn
              id="charterup-sync-continue"
              solo
              flat
              class="btn-primaryaction bold"
              @click="$refs.form.validate() ? (step = 2) : null"
            >
              Continue
            </v-btn>
          </v-layout>
        </v-stepper-content>

        <v-stepper-content style="height: 100%" step="2">
          <v-layout
            v-if="!loading"
            style="height: 100%; justify-content: space-between"
            column
          >
            <v-layout column>
              <h3 style="margin-bottom: 20px">
                Allow CharterUP access to the items below
              </h3>
              <div>Select the CoachRail Module you wish to give access</div>
              <div class="toggle-contents">
                <v-layout justify-end column>
                  <template v-for="(itemOne, itemOneIndex) in switchGroupOne">
                    <v-layout
                      v-if="
                        itemOne.key !== 'charterupRates' ||
                        showCharterUpRatesToggle
                      "
                      :key="`charterup-module-item-one-${itemOneIndex}`"
                      align-center
                      class="entry-row"
                      row
                    >
                      <v-flex xs6>{{ itemOne.label }}</v-flex>
                      <v-flex xs4>
                        <v-layout justify-end>
                          <v-flex xs3>
                            <v-switch
                              :id="`charterup-sync-toggle-${itemOne.key}`"
                              v-model="itemOne.status"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-layout>
                <v-layout justify-end column>
                  <v-layout
                    v-for="(itemTwo, itemTwoIndex) in switchGroupTwo"
                    :key="`charterup-module-item-two-${itemTwoIndex}`"
                    align-center
                    class="entry-row"
                    row
                  >
                    <v-flex xs6>{{ itemTwo.label }}</v-flex>
                    <v-flex xs4>
                      <v-layout justify-end>
                        <v-flex xs3>
                          <v-switch
                            :id="`charterup-sync-toggle-${itemTwo.key}`"
                            v-model="itemTwo.status"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </div>
              <div
                v-if="displayWarning"
                id="charterup-sync-customer-warning"
                class="warning-text"
              >
                Warning: CoachRail recommends not sharing your customers
              </div>
            </v-layout>
            <div class="disclaimer-text">
              By clicking {{ linked ? 'Update Settings' : 'Link Account' }}, I
              agree to the
              <a href="https://www.charterup.com/terms-and-conditions">
                Provider Terms &amp; Conditions
              </a>
            </div>
            <v-btn
              id="charterup-sync-confirm"
              solo
              flat
              class="btn-primaryaction bold"
              @click="apiCall"
            >
              {{ linked ? 'Update Settings' : 'Link Account' }}
            </v-btn>
          </v-layout>
          <v-layout
            v-else
            column
            style="
              height: 500px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <h2>{{ linked ? 'Updating Settings' : 'Linking Account' }}</h2>
            <v-progress-circular
              :size="100"
              color="primary"
              class="updating-loader"
              indeterminate
            />
          </v-layout>
        </v-stepper-content>

        <v-stepper-content style="height: 100%" step="3">
          <v-layout style="height: 100%; justify-content: space-between" column>
            <v-layout column>
              <div class="account-text">Account linked successfully</div>
              <CRIcon
                class="secured"
                :width="105"
                :height="105"
                style="margin: 18px 0"
              >
                done
              </CRIcon>
            </v-layout>
            <v-btn
              :id="`charterup-sync-finalize`"
              solo
              flat
              class="btn-primaryaction bold"
              @click="returnToSettings"
            >
              Close
            </v-btn>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
<script>
import { switchGroupOne, switchGroupTwo } from './LinkAccountModels'
import { authComputed } from '@/state/helpers'
import accountSync from '@/services/accountSync'
import companies from '@/services/companies'
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      linked: false,
      step: 1,
      companyName: '',
      dotNumber: '34543543',
      email: '',
      switchGroupOne,
      switchGroupTwo,
      displayWarning: false,
      loading: false,
      showCharterUpRatesToggle: false,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    'switchGroupTwo.customers.status'() {
      if (this.switchGroupTwo.customers.status === true) {
        this.displayWarning = true

        setTimeout(() => {
          this.displayWarning = false
        }, 15000)
      }
    },
  },
  async mounted() {
    this.dotNumber = this.currentUser?.company?.dotNumber
    this.email = this.currentUser?.company?.email
    this.companyName = this.currentUser?.companyName
    const existingSettings = await accountSync
      .getCharterUpSettings()
      .then((data) => data.data)

    const companyResponse = await companies.getCompany(
      this.currentUser.companyId
    )

    this.showCharterUpRatesToggle =
      companyResponse.data.company.partnerTypeId &&
      companyResponse.data.company.partnerTypeId !== 7

    if (existingSettings.charterupCoachrailSyncId) {
      this.step = 2
      this.linked = true
      this.companyName = existingSettings.companyName
      this.email = existingSettings.email

      for (const prop in this.switchGroupOne) {
        this.switchGroupOne[prop].status = existingSettings[prop]
      }

      for (const prop in this.switchGroupTwo) {
        this.switchGroupTwo[prop].status = existingSettings[prop]
      }
    }
  },
  methods: {
    isRequired,
    isNotEmpty,
    returnToSettings() {
      this.step = 2
      this.close()
    },
    async apiCall() {
      this.loading = true

      try {
        const body = {}

        for (const prop in this.switchGroupOne) {
          body[prop] = this.switchGroupOne[prop].status
        }

        for (const prop in this.switchGroupTwo) {
          body[prop] = this.switchGroupTwo[prop].status
        }

        body.email = this.email
        body.companyName = this.companyName

        if (this.linked) {
          await accountSync.editCharterUpSettings(body)
        } else {
          await accountSync.createCharterUpSettings(body)
        }

        setTimeout(() => {
          this.loading = false
          this.step = 3
          this.linked = true
          this.$forceUpdate()
        }, 3000)
      } catch (e) {
        this.loading = false
        this.step = 1
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.entry-row {
  height: 25px;
}

.warning-text {
  color: $red;
  margin-top: 5px;
  font-weight: 600;
}

.disclaimer-text {
  font-size: 12px;
  text-align: center;
}

.charterup-settings {
  background-color: $white;
}

.account-text {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.secured {
  font-size: 7.5em;
  align-self: center;
  margin-top: 3%;
  margin-bottom: 3%;
  color: $success;
}

.updating-loader {
  margin-top: 3%;
}

.toggle-contents {
  height: 360px;
  display: flex;
  margin-bottom: 24px;
}

.complete {
  border-color: $primary !important;
}

.incomplete {
  border-color: $gray-lighter !important;
}

.btn-primaryaction {
  margin-right: 0;
  margin-left: 0;
}

::v-deep .v-snack {
  position: absolute;
}

::v-deep .v-stepper {
  width: 100%;
  box-shadow: none;
  height: 100%;
}

::v-deep .v-stepper__wrapper {
  height: 100%;
}

::v-deep .v-stepper__header {
  box-shadow: none;
}

::v-deep .v-stepper__step {
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

::v-deep .v-stepper__step__step {
  height: 34px;
  width: 34px;
  font-size: 16px;
}

::v-deep
  .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background-color: $gray-lighter !important;
}

::v-deep .v-stepper__step:first-child {
  padding-right: 0;
  padding-left: 24px;
}

::v-deep .v-stepper__step:last-child {
  padding-left: 0;
  padding-right: 24px;
}

::v-deep .v-divider {
  margin-bottom: 0 !important;
  border-width: thin 0px 5px 0px;
}

::v-deep .accent--text {
  color: $primary !important;
}
</style>
