<template>
  <div class="inline">
    <div class="sheet">
      <div class="columns">
        <div class="column">
          <div class="tabs">
            <ul>
              <li
                :id="`${id}-payment-profile-li-select-payment-gateway`"
                :class="
                  selectedMainTab === 'payment-gateway' ? 'is-active' : ''
                "
                @click="selectedMainTab = 'payment-gateway'"
              >
                <a>Payment Gateway</a>
              </li>
              <li
                :id="`${id}-payment-profile-li-select-linked-accounts`"
                :class="selectedMainTab === 'account' ? 'is-active' : ''"
                @click="selectedMainTab = 'account'"
              >
                <a>Linked Accounts</a>
              </li>
              <li
                :id="`${id}-payment-profile-li-select-company-settings`"
                :class="
                  selectedMainTab === 'company-settings' ? 'is-active' : ''
                "
                @click="selectedMainTab = 'company-settings'"
              >
                <a>Company Settings</a>
              </li>
              <li
                v-if="isSuperAdmin && !isModeAdd"
                :id="`${id}-payment-profile-li-select-partnership-settings`"
                :class="
                  selectedMainTab === 'partnership-settings' ? 'is-active' : ''
                "
                @click="selectedMainTab = 'partnership-settings'"
              >
                <a>Partnership Settings</a>
              </li>

              <li
                v-if="isSuperAdmin && !isModeAdd"
                :id="`${id}-payment-profile-li-select-cupops-settings`"
                :class="
                  selectedMainTab === 'cupops-settings' ? 'is-active' : ''
                "
                @click="selectedMainTab = 'cupops-settings'"
              >
                <a>CharterUP for Operators</a>
              </li>
              <!-- TOGGLING OFF SUBSCRIPTION MANAGEMENT -->
              <!-- <li
                :class="
                  selectedMainTab === 'subscription-management'
                    ? 'is-active'
                    : ''
                "
                @click="selectedMainTab = 'subscription-management'"
              >
                <a>Subscription Management</a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div v-if="selectedMainTab === 'account'">
        <!-- <span class="account-link-info"
          ><v-icon color="primary" small>info</v-icon> The linked account will only be used to
          receive payments from charterUP
        </span> -->
        <LinkAccountContainer />
      </div>
      <div v-else-if="selectedMainTab === 'company-settings'">
        <CompanySettings
          :id="`${id}-payment-profile`"
          v-model="selectModels"
          :mode="mode"
          @update="
            (val) => {
              $emit('update', val)
            }
          "
        />
      </div>
      <div v-else-if="selectedMainTab === 'partnership-settings'">
        <PartnershipSettings
          :id="`${id}-payment-profile`"
          ref="partnershipsettings"
          :company="company"
          :mode="mode"
        />
      </div>

      <div v-else-if="selectedMainTab === 'cupops-settings'">
        <CupopsSettings
          :id="`${id}-payment-profile`"
          ref="partnershipsettings"
          :company="company"
          :mode="mode"
        />
      </div>
      <!-- TOGGLING OFF SUBSCRIPTION MANAGEMENT -->
      <!-- <div v-else-if="selectedMainTab === 'subscription-management'">
        Subscription Management: TODO
      </div> -->
      <v-layout
        v-else
        :style="`flex-wrap: wrap; background-color: ${$cr.theme.blueLight}`"
        layout
        row
      >
        <div
          v-for="(paymentGatewayType,
          paymentGatewayTypeIdx) of paymentGatewayTypes"
          :key="`payment-gateway-container-${paymentGatewayTypeIdx}`"
        >
          <GatewayForm
            :id="`${id}-payment-profile-${paymentGatewayTypeIdx}`"
            :payment-gateway-type-key="paymentGatewayType"
            :payment-gateway="getPaymentGatewayByTypeKey(paymentGatewayType)"
            :company="company"
            @close="close"
          />
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
import GatewayForm from './GatewayForm.vue'
import LinkAccountContainer from '@/components/LinkAccountContainer.vue'
import CompanySettings from '@/components/CompanySettings.vue'
import PartnershipSettings from '@/components/PartnershipSettings.vue'
import CupopsSettings from '@/components/CupopsSettings.vue'
import linkedAccounts from '@/services/linkedAccounts'
import { authComputed } from '@/state/helpers'

export default {
  components: {
    LinkAccountContainer,
    GatewayForm,
    CompanySettings,
    PartnershipSettings,
    CupopsSettings,
  },
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: () => 'view',
    },
    selectModels: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedMainTab: 'payment-gateway',
      showAccountsDialog: false,
      selectedAccountId: undefined,
      paymentGateways: [],
      paymentGatewayTypes: [],
    }
  },
  computed: {
    ...authComputed,
    isModeAdd() {
      return this.mode === 'add'
    },
  },
  async mounted() {
    await this.refreshData()
    if (this.$route.query?.link) {
      this.selectedMainTab = 'account'
    }
  },
  methods: {
    async refreshData() {
      const { linkedAccountProfileId } = this.company
      if (linkedAccountProfileId) {
        const linkedAccountDetailData = linkedAccounts.getLinkedAccountDetailByProfileId(
          linkedAccountProfileId
        )
        const linkedAccountDetail = linkedAccountDetailData.data || []
        this.linkedAccountDetails = linkedAccountDetail
      }
      const paymentGatewaysResult = await this.$store.dispatch(
        'payments/getAllPaymentGateways'
      )
      const paymentGateways = paymentGatewaysResult?.data?.paymentGateways || []
      this.paymentGateways = paymentGateways
      this.paymentGatewayTypes = []
      this.setPaymentGatewayTypes()
    },
    close() {
      this.refreshData()
    },
    getPaymentGatewayByTypeKey(key) {
      return this.paymentGateways.find((p) => p.paymentGatewayTypeKey === key)
    },
    setPaymentGatewayTypes() {
      this.paymentGatewayTypes = ['auth_net']
    },
  },
}
</script>

<style>
iframe {
  height: 100vh !important;
}
</style>

<style lang="scss" scoped>
@import '@/scss/bulma';

.inline {
  padding: 0 24px;
}

.account-link-info {
  color: $blue;
}

.linked-account-container {
  max-height: 140px;
  padding: 20px;
  text-align: center;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba($black-base, 0.1), 0 1px 3px rgba($black-base, 0.1);
}

.link-account-container {
  width: 270px;
  height: 200px;
  padding: 40px;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba($black-base, 0.1), 0 1px 3px rgba($black-base, 0.1);
}

.tabs {
  ul {
    border-bottom: none;
  }
  li:not(.is-active) a {
    margin-top: -3px;
    border: none !important;
  }
  li.is-active a {
    color: $primary;
    border-bottom: solid 3px $primary;
  }
}

.tab-data {
  min-height: 250px;
  padding: 28px;
  background-color: $blue-light;
}

.v-dialog--active::v-deep {
  border-radius: 8px;
}

.padded::v-deep {
  padding: 10px;
}

.account-container {
  margin: 20px;
  background-color: $blue-light;
  border-radius: 6px;
}

.link-account-label {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 6px;
  font-size: 16px;
  letter-spacing: 0;
}

.account-header {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}

.account-detail {
  font-size: 11px;
  line-height: unset;
  letter-spacing: 0;
}

.flexy {
  display: flex;
}

.accounts-container {
  overflow: scroll;
}
.container-filler {
  padding: 30px;
  background-color: $blue-light;
}
</style>
